<script setup lang="ts"></script>
<template>
  <div
    id="portal"
    class="absolute left-1/2 transform -translate-x-1/2 before:bg-haloA after:bg-haloB -z-20">
    <div id="portalHalo">

    </div>
  </div>
</template>
<style scoped>
  :root {
    --portal-blur: 64px;
    --portal-opacity: 0.1;
  }

  #portal:before,
  #portal:after {
    @apply absolute top-0 left-0 rounded-full w-full h-full aspect-square -z-20 bg-cover shadow-portal blur-sm saturate-[2];
  }

  #portal:before {
    opacity: 0.35;
    animation: fadeIn 20s ease-in-out infinite;
  }
  #portal:after {
    opacity: 0;
    animation: fadeOut 20s ease-in-out infinite;
  }

  #portalHalo {
    
    @apply opacity-0 w-full rounded-full aspect-square;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.35;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0.35;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.35;
    }
    100% {
      opacity: 0;
    }
  }
</style>
