<script setup lang="ts">
  import { useDonationStore } from '@/stores/donationStore'
  import { storeToRefs } from 'pinia'
  import { computed } from 'vue'

  const { donationRecord } = storeToRefs(useDonationStore())

  const shipmentStatus = computed(() => {
    if (!donationRecord.value.trackingStatus) return 'UNKNOWN'
    return donationRecord.value.trackingStatus.status
  })

  const trackingETA = computed(() => {
    console.log('trackingETA - donation', donationRecord.value)
    if (!donationRecord.value.trackingStatus) return
    const eta = new Date(donationRecord.value.trackingETA)
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayOfWeek = daysOfWeek[eta.getDay()]
    const dayOfMonth = eta.getDate()
    const hours = eta.getHours()
    const minutes = eta.getMinutes()
    const ampm = hours >= 12 ? 'pm' : 'am'
    let formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}${ampm}`
    if (hours === 0 && minutes === 0) {
      formattedTime = '9:00pm'
    }
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const monthName = monthNames[eta.getMonth()]
    const year = eta.getFullYear()

    return {
      dayOfWeek,
      dayOfMonth,
      monthName,
      year,
      formattedTime,
    }
  })

  const handleTrackingClick = () => {
    const trackingUrl = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${donationRecord.value.trackingNumber}`
    console.log('ShipmentStatusBlock - handleTrackingClick - trackingUrl', trackingUrl)
    window.open(trackingUrl, '_blank')
  }
</script>
<template>
  <div class="">
    <div class="font-ringside-narrow text-sm uppercase font-bold pb-5">Estimated Delivery:</div>
    <div
      v-if="shipmentStatus === 'TRANSIT'"
      class="flex">
      <div class="">
        <div class="uppercase pb-1">{{ trackingETA?.dayOfWeek }}</div>
        <div class="flex gap-2.5">
          <div class="flex-1 flex gap-2.5">
            <div class="font-ringside-narrow text-6xl leading-none font-bold">{{ trackingETA?.dayOfMonth }}</div>
            <div class="flex-1 flex flex-col-reverse pb-2">
              <div class="text-sm">{{ trackingETA?.year }}</div>
              <div class="text-sm">{{ trackingETA?.monthName }}</div>
            </div>
          </div>
          <div class="flex-1 flex flex-col-reverse pb-2">
            <div class="text-sm font-medium">{{ trackingETA?.formattedTime }}</div>
            <div class="text-xs font-thin">by</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="shipmentStatus === 'DELIVERED'"
      class="pb-2.5">
      Your materials have been delivered.
    </div>
    <div
      v-else
      class="pb-2.5">
      We are preparing your package for shipment.
    </div>
    <div
      v-if="donationRecord.trackingNumber"
      @click="handleTrackingClick"
      class="text-stone-500 text-xxs font-thin text-center w-full p-2 bg-stone-100 rounded-md leading-none cursor-pointer hover:text-stone-600 hover:bg-stone-300">
      View Tracking History
    </div>
  </div>
</template>
