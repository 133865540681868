<script setup lang="ts">
/**
 * The AuthenticationReset component handles the logout process for the user.
 * Intended to assist in resolving client-side errors.
 *
 * @description
 * This component signs the user out of their Firebase account, clears the local storage (including the cart),
 * and redirects the user to the home page.
 */
import { getAuth, signOut } from 'firebase/auth'
import router from '@/router'

// Get the router instance for navigation

// Get the Firebase authentication instance
const auth = getAuth()

// Initiate the sign-out process
signOut(auth)
  .then(() => {
    // User successfully signed out
    console.log({
      event: 'user-sign-out',
      status: 'success',
      message: 'User successfully signed out'
    })

    // Clear local storage (including the cart)
    window.localStorage.clear()

    // Redirect the user to the home page
    router.replace('/')
  })
  .catch((error) => {
    // An error occurred during sign-out
    console.error({
      event: 'user-sign-out',
      status: 'error',
      message: 'Error signing out',
      error: error.message // Include the error message
    })
  })
</script>

<template>
  <p class="greeting">Reset</p>
</template>

<style>
.greeting {
  color: red;
  font-weight: bold;
}
</style>
