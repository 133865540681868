<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <g
      stroke-linecap="round"
      stroke-width="1.5"
      fill="none"
      stroke-linejoin="round">
      <path d="M5 12h14M10 7l-5 5M10 17l-5-5" />
    </g>
  </svg>
</template>
