<script setup lang="ts">
  import { RouterLink } from 'vue-router'

  const props = defineProps<{
    to: string
    title: string
    useCase: string
    descriptions: string[]
    alignRight?: boolean
  }>()
</script>

<template>
  <RouterLink
    :to="props.to"
    class="productBlock"
    :class="[alignRight ? 'lg:col-start-3 ml-1.5' : 'text-right justify-self-end']">
    <div class="productTitle">{{ props.title }}</div>
    <div class="productUseCase">{{ props.useCase }}</div>
    <div
      v-for="(description, index) in props.descriptions"
      :key="index"
      class="productDescription"
      v-html="description"></div>
    <LearnMoreButton :alignRight="props.alignRight" />
  </RouterLink>
</template>

<style scoped>
  .productBlock {
    @apply flex flex-col;
  }
  .productTitle {
    @apply text-sm font-bold tracking-tight lg:text-base;
  }

  .productUseCase {
    @apply mb-2.5 font-ringside-narrow text-[17px] leading-tight tracking-tight text-stone-400 lg:text-xl;
  }

  .productDescription {
    @apply pt-1.5 text-xs leading-tight lg:text-sm;
  }
</style>
