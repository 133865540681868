<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 3"
    viewBox="0 0 24 24">
    <path
      d="M15.5 15.62878a5.15244 5.15244 0 0 1-7 0"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5" />
    <path
      d="M3 16.5v-5.54029a4.5 4.5 0 0 1 1.68885-3.5139L8.8765 4.09567a5 5 0 0 1 6.247 0l4.18765 3.35013A4.5 4.5 0 0 1 21 10.9597V16.5a4.5 4.5 0 0 1-4.5 4.5h-9A4.5 4.5 0 0 1 3 16.5Z"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5" />
  </svg>
</template>
