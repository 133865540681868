<script setup lang="ts">
  import BackgroundSoftness from '@/components/layout/blocks/BackgroundSoftness.vue'
  import { analytics } from '@/firebase'
  import { logEvent } from 'firebase/analytics'
  import { onBeforeMount } from 'vue'

  onBeforeMount(() => {
    logEvent(analytics, 'view_item_list', {
      item_list_id: 'hero',
      item_list_name: 'Hero Product Selection',
      items: [{ item_name: 'Liquid' }, { item_name: 'Tabs' }],
    })
  })
</script>

<template>
  <div class="flex flex-col min-h-screen relative">
    <BackgroundSoftness />
    <MessageBar />
    <ShopMenu />
    <div class="flex-1"></div>

    <div id="hero">
      <PortalBlock class="w-80 lg:w-[26rem] -top-16 sm:-top-6 lg:-top-24" />
      <div
        id="heroImage"
        class="opacity-0">
        <RouterLink
          to="/awaken/liquid"
          class="flex-1" />
        <RouterLink
          to="/awaken/tabs"
          class="flex-1" />
      </div>

      <ProductHeroBlock
        to="/awaken/liquid"
        title="AWAKEN Liquid"
        useCase="Structured days"
        :descriptions="[
          'A concentrated liquid extract for daily use.',
          'Best for feeling <br />open, present, and connected.',
        ]" />

      <ProductHeroBlock
        to="/awaken/tabs"
        title="AWAKEN Tabs"
        useCase="Unstructured days"
        :descriptions="[
          'Rapid dissolving tabs for routine self-care.',
          'Best for exploration, introspection, and decompression.',
        ]"
        :alignRight="true" />
    </div>
    <div class="flex-1"></div>
    <Footer></Footer>
  </div>
</template>
<style scoped>
  #hero {
    @apply relative mx-auto mt-16  grid max-w-[400px] auto-rows-auto gap-6 bg-hero bg-135% bg-top bg-no-repeat px-7 pb-0 sm:max-w-screen-sm sm:bg-96% sm:px-36 lg:max-w-screen-lg lg:auto-cols-auto lg:gap-5 lg:bg-hero_lg lg:bg-70% lg:px-7;
  }

  #heroImage {
    @apply col-span-2 mx-auto flex h-72 w-full lg:col-span-1 lg:col-start-2 lg:row-span-2 lg:h-96 lg:w-[26rem];
  }

  .productBlock {
    @apply flex flex-col row-start-2 lg:row-start-1 lg:w-44 lg:mt-5 lg:mx-5 lg:pt-10 sm:pt-5;
  }
  .productTitle {
    @apply text-sm font-bold tracking-tight sm:pt-10 lg:text-base;
  }

  .productUseCase {
    @apply mb-2.5 font-ringside-narrow text-[17px] leading-tight tracking-tight text-stone-400 lg:text-xl;
  }

  .productDescription {
    @apply pt-1.5 text-xs leading-tight lg:text-sm;
  }
</style>
