<script setup lang="ts">
  import { onMounted, onUnmounted, ref } from 'vue'
  import { useCartStore } from '@/stores/cartStore'
  import { storeToRefs } from 'pinia'
  import { useDonationStore } from '@/stores/donationStore'

  const { totalQty } = storeToRefs(useCartStore())
  const floatingCartOpacity = ref(1)
  const { donationId } = storeToRefs(useDonationStore())

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
    console.log('ShopMenu onMounted')
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    floatingCartOpacity.value = 0
    setTimeout(() => {
      floatingCartOpacity.value = 1
    }, 2000)
  }
</script>
<template>
  <div
    id="menu-bar"
    class="text-stone-500 py-8 lg:mb-20">
    <div class="mx-auto max-w-screen-lg flex justify-between px-8">
      <RouterLink to="/"><IconLogo class="flex-none mr-2 mt-[0.2rem] w-6 md:w-8" /></RouterLink>
      <div class="flex-1"></div>
      <RouterLink
        @click="donationId = null"
        to="/donations"
        class="mt-[5px] self-start text-sm pr-10">
        <IconHomeSmile class="w-6 inline-block -mt-1 mr-2 stroke-stone-500 hover:stroke-stone-600"></IconHomeSmile
        >Donations
      </RouterLink>
      <RouterLink
        to="/checkout"
        class="mt-[5px]"
        ><div class="cursor-pointer text-sm">
          <IconCart class="w-6 inline-block -mt-1 mr-1 stroke-stone-500 hover:stroke-stone-600"></IconCart>
          Cart <template v-if="totalQty > 0">({{ totalQty }})</template>
        </div>
      </RouterLink>
    </div>
  </div>
  <RouterLink
    v-if="totalQty > 0"
    id="floatingCart"
    to="/checkout"
    class="fixed bottom-10 right-10 bg-stone-600 text-stone-50 p-5 rounded-full z-50"
    :style="{ opacity: floatingCartOpacity }">
    <div class="cursor-pointer text-sm">
      <IconCart class="w-6 inline-block -mt-1 mr-1 stroke-stone-100 hover:stroke-stone-50" />
      Cart
      <template v-if="totalQty > 0">({{ totalQty }})</template>
    </div>
  </RouterLink>
</template>
<style scoped>
  #floatingCart {
    transition: opacity 0.5s ease-in-out;
  }
</style>
