<script setup></script>
<template>
  <div
    id="message-bar"
    class="p-3 bg-stone-700 text-center text-xxs md:text-xs text-stone-50 w-full"
  >
    Enjoy complementary carbon-neutral shipping on all orders.
  </div>
</template>
<style scoped></style>
