<script setup lang="ts">
const props = defineProps<{
  title: string
  description: string
  selection: string
  skuMask: string
}>()

const emit = defineEmits(['variation-change'])

const handleClick = () => {
  emit('variation-change', props.skuMask)
}
</script>

<template>
  <div @click="handleClick">
    <div
      class="flex-1 box-border border border-solid border-stone-300 hover:border-stone-400 cursor-pointer rounded-lg p-5"
      :class="
        title == selection ? 'border-2 border-solid border-stone-500 bg-stone-50 shadow-inner' : ''
      "
    >
      <div class="font-ringside-narrow font-bold uppercase text-xs text-stone-500 pb-0.5">
        {{ title }}
      </div>
      <div class="text-xs">{{ description }}</div>
    </div>
  </div>
</template>

<style scoped></style>
