<script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router'

  const router = useRouter()
  const route = useRoute()

  import { ref, onMounted } from 'vue'
  import { RecaptchaVerifier, signInWithPhoneNumber, type ConfirmationResult } from 'firebase/auth'
  import { useCurrentUser, useFirebaseAuth } from 'vuefire'
  import { logEvent } from 'firebase/analytics'
  import { analytics } from '@/firebase'
  import { useAwakenRole } from '@/utilities/useAwakenRole'
  import { getFunctions, httpsCallable } from 'firebase/functions'
  import { until } from '@vueuse/core'
  import { storeToRefs } from 'pinia'
  import { useMemberStore } from '@/stores/memberStore'
  import { useMemberRecordComplete } from '@/utilities/useMemberRecordComplete'

  const auth = useFirebaseAuth()
  const functions = getFunctions()
  const validateReferral = httpsCallable(functions, 'api-validateReferral')
  const updateMemberRecordFunction = httpsCallable(functions, 'api-updateMemberRecord')
  const formReady = ref(true)
  const step = ref('')
  const mobileNumber = ref('')
  const smsVerificationCode = ref('')
  const referralCode = ref('')
  const ageOfConsent = ref(false)
  const religiousIntent = ref(false)
  const agreeToTerms = ref(false)
  const preferredName = ref('')
  const lastName = ref('')
  const email = ref('')
  const helpText = ref('')
  const isSubmitting = ref(false)
  const confirmationResult = ref<ConfirmationResult>()
  const recaptchaVerifier = ref<RecaptchaVerifier>()
  const { memberRecord } = storeToRefs(useMemberStore())
  const { hasAwakenRole, refreshAwakenRoleStatus } = useAwakenRole()
  const { hasCompleteMemberRecord } = useMemberRecordComplete()
  // prettier-ignore
  const canadianAreaCodes = new Set([
        '204', '226', '236', '249', '250', '306', '343', '403', '416', '418',
        '431', '450', '506', '514', '519', '548', '581', '604', '613', '620',
        '647', '705', '709', '778', '780', '807', '819', '825', '867', '902',
        '905',
      ])
  // prettier-format

  function setupRecaptchaVerifier() {
    if (!auth) return

    // Remove existing div
    const existingDiv = document.getElementById('recaptchaVerifier')
    if (existingDiv) {
      existingDiv.remove()
    }

    // Create new div
    const newDiv = document.createElement('div')
    newDiv.id = 'recaptchaVerifier'
    newDiv.className = 'flex-1'

    // Insert into content container
    document.getElementById('content')?.appendChild(newDiv)

    // Clear existing verifier
    recaptchaVerifier.value?.clear()

    // Create new verifier
    recaptchaVerifier.value = new RecaptchaVerifier(auth, 'recaptchaVerifier', {
      size: 'invisible',
      callback: () => {
        console.log('reCAPTCHA solved')
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired')
        setupRecaptchaVerifier()
      },
    })

    // Force render to ensure it's ready
    return recaptchaVerifier.value.render()
  }

  // TODO synchronize name and email to membership form

  async function resetAuthForm() {
    setupRecaptchaVerifier()

    formReady.value = true
    step.value = 'requestMobileNumber'
    mobileNumber.value = ''
    smsVerificationCode.value = ''
    helpText.value = ''
    referralCode.value = ''
    ageOfConsent.value = false
    religiousIntent.value = false
    agreeToTerms.value = false
    preferredName.value = ''
    lastName.value = ''
    email.value = ''
  }

  async function handleMobileNumberSubmit() {
    formReady.value = false
    isSubmitting.value = true
    await setupRecaptchaVerifier()

    if (!recaptchaVerifier.value) {
      router.go(0)
      return
    }

    const phoneNumber = mobileNumber.value.replace(/-/g, '')

    if (canadianAreaCodes.has(phoneNumber.slice(0, 3))) {
      resetAuthForm()
      helpText.value = 'Please enter a valid US mobile phone number.'
      return
    }
    try {
      confirmationResult.value = await signInWithPhoneNumber(auth!, '+1' + phoneNumber, recaptchaVerifier.value)
      helpText.value = ''
      step.value = 'requestVerificationCode'
    } catch (error: any) {
      await setupRecaptchaVerifier()
      resetAuthForm()
      console.error('Error details:', {
        code: error.code,
        message: error.message,
        fullError: error,
      })

      switch (error.code) {
        case 'auth/invalid-phone-number':
          helpText.value = 'Invalid phone number format.'
          break
        case 'auth/too-many-requests':
          helpText.value = 'Too many attempts. Please try again later.'
          break
        case 'auth/invalid-app-credential':
          helpText.value = 'Authentication service temporarily unavailable. Please try again in a few minutes.'
          break
        default:
          helpText.value = 'An error occurred. Please try again or send us a message: hello@method.foundation'
      }
    } finally {
      isSubmitting.value = false
      formReady.value = true
    }
  }

  async function handleVerificationCodeSubmit() {
    formReady.value = false
    isSubmitting.value = true

    if (!confirmationResult.value) {
      await setupRecaptchaVerifier()
      step.value = 'requestMobileNumber'
      isSubmitting.value = false
      formReady.value = true
      return
    }

    try {
      await confirmationResult.value.confirm(smsVerificationCode.value)
    } catch (err) {
      console.error('AuthenticationView - handleVerificationCodeSubmit: Error verifying code:', err)
      step.value = 'validationCodeError'
      isSubmitting.value = false
      formReady.value = true
      return
    }

    try {
      await refreshAwakenRoleStatus()

      await until(() => memberRecord.value !== undefined).toBeTruthy()

      if (!hasAwakenRole.value) {
        formReady.value = true
        isSubmitting.value = false
        step.value = 'requestReferrer'
      } else if (!hasCompleteMemberRecord.value) {
        preferredName.value = memberRecord.value?.preferredName || ''
        lastName.value = memberRecord.value?.lastName || ''
        email.value = memberRecord.value?.email || ''
        formReady.value = true
        isSubmitting.value = false
        step.value = 'memberApplication'
      } else {
        await handleAuthenticationComplete()
      }
    } catch (error) {
      console.error('Error during authentication completion:', error)
      formReady.value = true
      isSubmitting.value = false
      step.value = 'requestReferrer'
    }
  }

  interface ValidateReferralResult {
    success: boolean
    message: string
  }
  async function handleReferralSubmit() {
    formReady.value = false
    isSubmitting.value = true
    try {
      const result = (await validateReferral({ referral: referralCode.value.replace(/[^a-zA-Z0-9]/g, '') })) as {
        data: ValidateReferralResult
      }
      if (result.data.success) {
        helpText.value = ''
        await until(() => memberRecord.value !== undefined).toBeTruthy()

        if (!hasCompleteMemberRecord.value) {
          preferredName.value = memberRecord.value?.preferredName
          lastName.value = memberRecord.value?.lastName
          email.value = memberRecord.value?.email
          formReady.value = true
          step.value = 'memberApplication'
        } else {
          handleAuthenticationComplete()
        }
      } else {
        helpText.value = 'An error occurred. Please try again or send us a message: hello@method.foundation'
      }
    } catch (error) {
      console.error('Error validating referral:', error)
      helpText.value = 'An error occurred. Please try again or send us a message: hello@method.foundation'
    } finally {
      formReady.value = true
      isSubmitting.value = false
      if (helpText.value) {
        referralCode.value = ''
      }
    }
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  async function handleMemberApplicationSubmit() {
    if (!isValidEmail(email.value)) {
      helpText.value = 'Please enter a valid email address'
      return
    }
    formReady.value = false
    isSubmitting.value = true
    if (!preferredName.value) {
      helpText.value = 'Please provide your preferred/first name.'
      formReady.value = true
      isSubmitting.value = false
      return
    }
    if (!lastName.value) {
      helpText.value = 'Please provide your last name.'
      formReady.value = true
      isSubmitting.value = false
      return
    }
    if (!email.value) {
      helpText.value = 'Please provide a valid email address.'
      formReady.value = true
      isSubmitting.value = false
      return
    }
    if (!ageOfConsent.value || !religiousIntent.value || !agreeToTerms.value) {
      helpText.value = 'Please agree to the terms and conditions.'
      formReady.value = true
      isSubmitting.value = false
      return
    }

    // TODO validate email exists with zerobounce

    const newMemberData = {
      preferredName: preferredName.value,
      lastName: lastName.value,
      email: email.value,
      termsAgreed: true,
    }
    console.log('AuthenticationView - newMemberData', newMemberData)

    try {
      await updateMemberRecordFunction(newMemberData)
    } catch (error) {
      helpText.value = 'An error occurred. Please try again or send us a message: hello@method.foundation'
      formReady.value = true
      isSubmitting.value = false
    }

    logEvent(analytics, 'sign_up', { referral: referralCode.value, success: true })

    await handleAuthenticationComplete()
  }

  async function handleAuthenticationComplete() {
    console.log('AuthenticationView - handleAuthenticationComplete started')
    logEvent(analytics, 'login', { method: 'mobileNumber' })
    const redirectPath = (route.query.redirect as string) || '/'
    console.log('AuthenticationView - redirectPath', redirectPath)
    await router.replace(redirectPath).catch((err) => {
      console.error('Navigation failed:', err)
    })
    console.log('AuthenticationView - handleAuthenticationComplete finished')
  }

  onMounted(async () => {
    console.log('AuthenticationView - onMounted start')
    setupRecaptchaVerifier()

    const member = useCurrentUser()
    await until(member).not.toBeUndefined()

    if (!member.value) {
      step.value = 'requestMobileNumber'
      return
    }

    await refreshAwakenRoleStatus()
    await until(() => memberRecord.value !== undefined && hasAwakenRole.value !== undefined).toBeTruthy()

    if (!hasAwakenRole.value) {
      step.value = 'requestReferrer'
    } else if (!hasCompleteMemberRecord.value) {
      preferredName.value = memberRecord.value?.preferredName
      lastName.value = memberRecord.value?.lastName
      email.value = memberRecord.value?.email
      formReady.value = true
      step.value = 'memberApplication'
    } else {
      handleAuthenticationComplete()
    }
  })
</script>

<template>
  <div class="flex flex-col min-h-screen justify-between">
    <div
      id="message-bar"
      class="p-2.5 bg-stone-700 text-center text-xxs md:text-xs text-stone-50 w-full">
      <template v-if="step != 'memberApplication'">Member Access Portal</template>
      <template v-else>New Member Application</template>
    </div>
    <div
      id="content"
      class="flex flex-col py-10 mx-auto sm:px-16 w-80 sm:w-[31rem]">
      <FadeTransition mode="out-in">
        <div
          v-if="step == 'requestMobileNumber'"
          class="transition-all duration-300"
          key="mobileNumber">
          <form @submit.prevent="handleMobileNumberSubmit">
            <label
              class=""
              for="mobileNumber"
              >Mobile Number:</label
            >
            <input
              id="mobileNumber"
              v-model="mobileNumber"
              type="text"
              required
              v-maska
              placeholder="000-000-0000"
              autocomplete="tel-national"
              pattern="[2-9]\d{2}-\d{3}-\d{4}"
              data-maska="Z##-###-####"
              data-maska-tokens="Z:[2-9]"
              class="" />
            <div
              v-if="helpText != ''"
              class="py-2.5 text-xs text-stone-400">
              {{ helpText }}
            </div>
            <button
              type="submit"
              :disabled="!formReady || isSubmitting">
              <template v-if="isSubmitting">
                <span class="ellipsisWave">●</span><span class="ellipsisWave ellipsisWave-delay-300"> ● </span
                ><span class="ellipsisWave ellipsisWave-delay-600">●</span>
              </template>
              <template v-else> Sign In<span class="px-2.5 text-stone-400">|</span>Sign up </template>
            </button>
          </form>
          <div class="text-xxs pt-2.5 text-stone-400 text-center">Standard message and data rates may apply.</div>
        </div>
        <div
          v-else-if="step == 'requestVerificationCode'"
          class="transition-all duration-300"
          key="verificationCode">
          <form @submit.prevent="handleVerificationCodeSubmit">
            <label for="smsVerificationCode">Verification Code: </label>
            <input
              id="smsVerificationCode"
              v-model="smsVerificationCode"
              type="text"
              required
              v-maska
              placeholder="000000"
              autocomplete="one-time-code"
              pattern="[0-9]{6}"
              data-maska="######"
              class="" />
            <div
              v-if="helpText != ''"
              class="py-2.5 text-xs text-stone-400">
              {{ helpText }}
            </div>
            <button
              type="submit"
              :disabled="!formReady || isSubmitting">
              <template v-if="isSubmitting">
                <span class="ellipsisWave">●</span><span class="ellipsisWave ellipsisWave-delay-300"> ● </span
                ><span class="ellipsisWave ellipsisWave-delay-600">●</span>
              </template>
              <template v-else>Verify</template>
            </button>
          </form>
        </div>

        <div
          v-else-if="step == 'validationCodeError'"
          class="flex flex-col text-center transition-all duration-300"
          key="validationError">
          <div class="text-lg font-ringside-narrow font-bold p-5">Invalid Verification Code</div>
          <div
            class="text-sm underline cursor-pointer"
            @click="resetAuthForm">
            Try Again
          </div>
        </div>

        <div
          v-else-if="step == 'requestReferrer'"
          class="transition-all duration-300"
          key="referrer">
          <form @submit.prevent="handleReferralSubmit">
            <label for="smsVerificationCode">Referral: </label>
            <input
              id="referralCode"
              v-model="referralCode"
              type="text"
              required
              v-maska
              placeholder="000-000-0000"
              pattern="([A-Za-z0-9]{6}|[0-9]{3}-[0-9]{3}-[0-9]{4})"
              data-maska="['******','###-###-####']"
              class="" />
            <div
              v-if="helpText != ''"
              class="py-2.5 text-xs text-stone-400">
              {{ helpText }}
            </div>
            <div
              v-else
              class="py-2.5 text-xs text-stone-400">
              Enter referrer's mobile number or secret.
            </div>
            <button
              type="submit"
              :disabled="!formReady || isSubmitting">
              <template v-if="isSubmitting">
                <span class="ellipsisWave">●</span><span class="ellipsisWave ellipsisWave-delay-300">��● </span
                ><span class="ellipsisWave ellipsisWave-delay-600">●</span>
              </template>
              <template v-else>Verify</template>
            </button>
          </form>
        </div>
        <div
          v-else-if="step == 'memberApplication'"
          class="transition-all duration-300"
          key="memberApplication">
          <div class="hidden text-center pb-10 text-xl font-ringside-narrow font-thin tracking-tight text-stone-400">
            New Member Application
          </div>
          <form @submit.prevent="handleMemberApplicationSubmit">
            <div class="flex justify-between">
              <div class="w-1/2 flex flex-col pr-2.5">
                <label for="smsVerificationCode">Preferred Name: </label>
                <input
                  id="preferredNme"
                  v-model="preferredName"
                  type="text"
                  required
                  placeholder="First"
                  autocomplete="given-name"
                  pattern="[A-Za-z -]+" />
              </div>
              <div class="w-1/2 flex flex-col pl-2.5">
                <label for="smsVerificationCode">Last Name: </label>
                <input
                  id="lastName"
                  v-model="lastName"
                  type="text"
                  required
                  placeholder="Last"
                  pattern="[A-Za-z -]+"
                  autocomplete="family-name" />
              </div>
            </div>
            <label
              for="smsVerificationCode"
              class="pt-5"
              >Email:
            </label>
            <input
              id="email"
              v-model="email"
              type="email"
              required
              placeholder="email@address.org"
              class="" />
            <div class="flex pt-10">
              <input
                type="checkbox"
                v-model="ageOfConsent"
                required />
              <div class="text-xs pt-0.5 text-stone-400">I am at least 21 years of age.</div>
            </div>
            <div class="flex pt-2.5">
              <input
                type="checkbox"
                v-model="religiousIntent"
                required />
              <div class="text-xs pt-0.5 text-stone-400">Materials are intended for religious purposes.</div>
            </div>
            <div class="flex pt-2.5 pb-5">
              <input
                type="checkbox"
                v-model="agreeToTerms"
                required />
              <div class="text-xs text-stone-400">
                I have read and agree to the
                <router-link
                  to="/legal#membership_agreement"
                  class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                  >Membership Agreement</router-link
                >,
                <router-link
                  to="/legal#terms_and_conditions"
                  class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                  >Terms & Conditions</router-link
                >, and
                <router-link
                  to="/legal#privacy_policy"
                  class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                  >Privacy Policy</router-link
                >.
              </div>
            </div>
            <div
              v-if="helpText != ''"
              class="py-2.5 text-xs text-stone-400">
              {{ helpText }}
            </div>

            <button
              type="submit"
              :disabled="!formReady || isSubmitting">
              <template v-if="isSubmitting">
                <span class="ellipsisWave">●</span><span class="ellipsisWave ellipsisWave-delay-300"> ● </span
                ><span class="ellipsisWave ellipsisWave-delay-600">●</span>
              </template>
              <template v-else>Submit Application</template>
            </button>
          </form>
        </div>

        <div
          v-else
          class="flex flex-col transition-all duration-300"
          key="loading">
          <div class="text-lg text-center">
            One moment please<span class="ellipsisWave">.</span>
            <span class="ellipsisWave ellipsisWave-delay-300">.</span>
            <span class="ellipsisWave ellipsisWave-delay-600">.</span>
          </div>
        </div>
      </FadeTransition>

      <div
        id="recaptchaVerifier"
        class="flex-1"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<style scoped>
  form {
    @apply flex-1 flex flex-col pt-5 mx-auto;
  }
  label {
    @apply text-sm pb-2 font-ringside-narrow font-medium uppercase;
  }
  input[type='text'],
  input[type='email'] {
    @apply flex-1 text-base rounded-lg shadow-inner border border-solid border-stone-200 p-2.5 pt-4 text-center placeholder:font-ringside-narrow placeholder:font-light;
  }
  input[type='checkbox'] {
    @apply appearance-none flex-shrink-0 w-4 h-4 rounded-sm bg-stone-100 shadow-inner border border-solid border-stone-300 mr-2.5 cursor-pointer;
  }
  input[type='checkbox']:checked {
    background-image: url('@/assets/images/checkmark.svg');
    @apply bg-stone-400 bg-contain;
  }
  button,
  input[type='submit'] {
    @apply mt-5 p-3 text-stone-50 text-sm disabled:bg-red-300 bg-stone-500 rounded-lg text-center cursor-pointer hover:bg-stone-600 transition duration-200;
  }
  form:invalid input[type='submit'],
  input[type='submit']:disabled,
  button:disabled {
    @apply bg-stone-400;
  }
  .ellipsisWave {
    animation: wave 900ms linear infinite;
  }
  .ellipsisWave-delay-300 {
    animation-delay: 300ms;
  }

  .ellipsisWave-delay-600 {
    animation-delay: 600ms;
  }
  @keyframes wave {
    0% {
      color: #fafaf9;
    } /* stone-50 */
    25% {
      color: #f5f5f4;
    } /* stone-100 */
    50% {
      color: #e7e5e4;
    } /* stone-200 */
    75% {
      color: #d6d3d1;
    } /* stone-300 */
    100% {
      color: #a8a29e;
    } /* stone-400 */
  }
</style>

<style>
  #recaptchaVerifier {
    opacity: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
</style>
