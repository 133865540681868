import { watch, type Ref } from 'vue'
import CartCheckoutError from './CartCheckoutError'
import { useCartStore } from '@/stores/cartStore'
import { useDonationStore } from '@/stores/donationStore'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { useAnalyticsItemGenerators } from '../analytics/AnalyticsItemGenerators'

export async function attemptPayment(
  isCheckoutButtonDisabled: Ref<boolean>,
  helpText: Ref<string>,
  fadeOutCallback: () => void
) {
  const { grandTotal } = storeToRefs(useCartStore())
  const { stripeCustomCheckout, stripeCustomCheckoutSessionId } = storeToRefs(useCartStore())
  const { donationId, donationRecord } = storeToRefs(useDonationStore())
  const { getDonationForSessionId } = useDonationStore()
  const { generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()

  const session = stripeCustomCheckout.value?.session()

  const confirmationRequirements = session?.confirmationRequirements || []
  if (confirmationRequirements.length > 0) {
    isCheckoutButtonDisabled.value = false
    helpText.value = 'Additional information required.'
    throw new CartCheckoutError('Additional information required.', session?.confirmationRequirements)
  }

  const result = await stripeCustomCheckout.value?.confirm()
  if (result?.error) {
    console.error('Error during payment confirmation.', result)
    helpText.value = 'An error occurred during payment.'
    isCheckoutButtonDisabled.value = false
  } else {
    if (stripeCustomCheckoutSessionId.value) {
      logEvent(analytics, 'purchase', {
        value: grandTotal.value,
        currency: 'USD',
        transaction_id: stripeCustomCheckoutSessionId.value,
        coupon: session?.discountAmounts?.[0]?.displayName,
        items: generateAnalyticsItemsFromCart(),
      })
    }

    watch(donationRecord, (newDonationRecord) => {
      if (newDonationRecord) {
        router.push({ name: 'donations' })
      }
    })

    donationId.value = null

    console.log('attemptPayment - stripeCustomCheckoutSessionId', stripeCustomCheckoutSessionId)
    if (stripeCustomCheckoutSessionId.value) {
      console.log('attemptPayment - stripeCustomCheckoutSessionId.value')
      getDonationForSessionId(stripeCustomCheckoutSessionId.value)
    }
    fadeOutCallback()
    useCartStore().clearCart()
  }
}
