<script setup lang="ts">
  import { Timestamp } from 'firebase/firestore'
  import { computed } from 'vue'
  import { type Donation } from '@/utilities/DonationView/Donation'
  import { storeToRefs } from 'pinia'
  import { useDonationStore } from '@/stores/donationStore'

  const props = defineProps<{
    donation: Donation
  }>()

  const { donationId } = storeToRefs(useDonationStore())

  const formattedDate = computed(() => {
    if (props.donation.timestamp instanceof Timestamp) {
      const date = props.donation.timestamp.toDate()
      return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`
    }
    return ''
  })

  const shippingName = computed(() => {
    if (props.donation.stripeCheckoutSession) {
      return (props.donation.stripeCheckoutSession as any).customer.shipping.name
    }
    return ''
  })

  const shippingLine1 = computed(() => {
    if (props.donation.stripeCheckoutSession) {
      return (props.donation.stripeCheckoutSession as any).customer.shipping.address.line1
    }
    return ''
  })

  const shippingLine2 = computed(() => {
    if (props.donation.stripeCheckoutSession) {
      return (props.donation.stripeCheckoutSession as any).customer.shipping.address.line2
    }
    return ''
  })
  const cityStateZip = computed(() => {
    if (props.donation.stripeCheckoutSession) {
      const city = (props.donation.stripeCheckoutSession as any).customer.shipping.address.city
      const state = (props.donation.stripeCheckoutSession as any).customer.shipping.address.state
      const zip = (props.donation.stripeCheckoutSession as any).customer.shipping.address.postal_code
      return city + ', ' + state + '  ' + zip
    }
    return ''
  })

  const trackingStatus = computed(() => {
    if (props.donation.trackingStatus) {
      return (props.donation.trackingStatus as any).status
    }
    return ''
  })

  const total = computed(() => {
    if (props.donation.stripeCheckoutSession) {
      return (props.donation.stripeCheckoutSession as any).amount_total / 100
    }
    return ''
  })

  const handleClick = () => {
    donationId.value = props.donation.id
  }
</script>
<template>
  <div
    @click="handleClick"
    class="flex items-center py-5 md:px-5 text-sm border-b border-solid border-stone-400 cursor-pointer">
    <div class="flex-2 md:flex-1 pr-5 md:pr-10 flex flex-col">
      <div class="font-bold text-xs font-ringside-narrow">{{ formattedDate }}</div>
      <div class="pt-1 font-ringside-narrow">
        {{
          total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        }}
      </div>
    </div>
    <div class="flex-3 md:flex-2 flex flex-col text-xs">
      <div class="font-bold">{{ shippingName }}</div>
      <div>{{ shippingLine1 }}</div>
      <div v-if="shippingLine2">{{ shippingLine2 }}</div>
      <div>{{ cityStateZip }}</div>
    </div>
    <div class="hidden md:block flex-1 text-sm text-right">{{ trackingStatus }}</div>
  </div>
</template>
