<template>
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="none">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M5.969,6.625l-0.667,-3h-1.929"></path>
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M7.731,14.835l-1.762,-8.21h12.658c0.637,0 1.111,0.587 0.978,1.21l-1.502,7c-0.099,0.461 -0.506,0.79 -0.978,0.79h-8.417c-0.471,0 -0.878,-0.329 -0.977,-0.79Z"></path>
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M17.465,19.25c-0.207,0 -0.375,0.168 -0.373,0.375c0,0.207 0.168,0.375 0.375,0.375c0.207,0 0.375,-0.168 0.375,-0.375c-0.001,-0.207 -0.169,-0.375 -0.377,-0.375"></path>
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M8.856,19.25c-0.207,0 -0.375,0.168 -0.373,0.375c-0.001,0.207 0.167,0.375 0.374,0.375c0.207,0 0.375,-0.168 0.375,-0.375c0,-0.207 -0.168,-0.375 -0.376,-0.375"></path>
    </g>
  </svg>
</template>
