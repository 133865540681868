import { firestore } from '@/firebase'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'
import { collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore'

export const useDonationStore = defineStore(
  'donation',
  () => {
    const donationId = ref<string | null>(null)
    const donationRecord = ref()
    const member = useCurrentUser()
    const donationDocRef = ref()
    useDocument(donationDocRef, { target: donationRecord })

    async function getDonationForSessionId(sessionId: string) {
      if (!member.value) return

      const q = query(
        collection(firestore, 'donations'),
        where('memberId', '==', member.value.uid),
        where('stripeCheckoutSessionId', '==', sessionId)
      )
      const snapshot = await getDocs(q)
      if (!snapshot.empty) {
        donationId.value = snapshot.docs[0].id
      } else {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          if (!querySnapshot.empty) {
            donationId.value = querySnapshot.docs[0].id
            unsubscribe()
          }
        })
      }
    }

    watch(
      [donationId],
      async () => {
        if (donationId.value) {
          donationDocRef.value = doc(firestore, `donations/${donationId.value}`)
        } else {
          donationRecord.value = null
        }
      },
      { immediate: true }
    )

    return {
      donationId,
      donationRecord,
      getDonationForSessionId,
    }
  },
  { persist: false }
)
