<script setup lang="ts">
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'

defineProps<{
  subtitle: string
  statement: string
}>()

const subtitleBlock = ref(null)
const subtitleIsVisible = useElementVisibility(subtitleBlock)
const statementBlock = ref(null)
const statementBlockIsVisible = useElementVisibility(statementBlock)
</script>

<template>
  <div>
    <div
      ref="subtitleBlock"
      :class="{ 'in-view': subtitleIsVisible }"
      class="subtitle font-ringside-narrow text-lg font-medium tracking-tight pb-3"
    >
      {{ subtitle }}
    </div>
    <div
      ref="statementBlock"
      
      class="statement text-2xl pr-0 md:pr-28 font-light text-stone-400 border-solid border-b-[5px] border-stone-400 pb-10"
    >
      <div :class="{ 'in-view': statementBlockIsVisible }">{{ statement }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>
@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translate(-0.5rem);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.subtitle.in-view {
  animation: fade-down 2s both;
  @apply animate-once animate-delay-200 animate-duration-[750ms];
}
.statement .in-view {
  animation: fade-right 1s both;
  @apply animate-once animate-delay-200 animate-duration-[750ms];
}
</style>
