<script setup lang="ts"></script>
<template>
  <transition name="fade">
    <slot />
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 100ms;
}

.fade-enter-active {
  transition-delay: 100ms;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
