import type { StripeCustomCheckoutElementsOptions } from '@stripe/stripe-js'

export const elementsOptions: StripeCustomCheckoutElementsOptions = {
  fonts: [
    {
      family: 'Ringside Regular Space',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rs/RingsideRegularSSm-Book-TD-Space.woff)',
      weight: '400',
    },
    {
      family: 'Ringside Regular',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rs/RingsideRegularSSm-Book-TD.woff)',
      weight: '400',
    },
    {
      family: 'Ringside Narrow',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rs/RingsideNarrowSSm-Light-TD.woff)',
      weight: '300',
    },
    {
      family: 'Ringside Narrow Space',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rs/RingsideNarrowSSm-Light-TD-Space.woff)',
      weight: '300',
    },
    {
      family: 'Ringside Condensed',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rngsdenrrw/RingsideCondensedSSm-Bold-TD.woff)',
      weight: '500',
    },
    {
      family: 'Ringside Condensed Space',
      src: 'url(https://timothyjoelwright.github.io/mthdrngsde/rngsdenrrw/RingsideCondensedSSm-Bold-TD-Space.woff)',
      weight: '500',
    },
  ],
  appearance: {
    theme: 'flat',
    variables: {
      colorText: '#78716c',
      colorBackground: '#fafaf9',
      tabIconSelectedColor: '#78716c',
      fontFamily: 'Ringside Regular Space, Ringside Regular, sans-serif',
      borderRadius: '0',
    },
    rules: {
      '.Label': {
        padding: '0 0 0 4px',
        textTransform: 'uppercase',
        fontFamily: 'Ringside Condensed Space, Ringside Condensed, sans-serif',
        fontSize: '14px',
        color: '#78716c',
      },
      '.Input': {
        padding: '10px',
        backgroundColor: 'var(--p-colorBackgroundLightenAbsolute05)',
        borderBottom: '1px solid #e7e5e4',
        marginBottom: '1.25rem',
      },
      '.Input::placeholder': {
        fontWeight: '300',
        color: '#a8a29e',
        fontFamily: 'Ringside Narrow Space, Ringside Narrow, sans-serif',
      },
      '.CheckboxInput': {
        backgroundColor: '#f5f5f4',
      },
      '.CheckboxInput--checked': {
        backgroundColor: '#78716c',
      },
      '.CheckboxLabel': {
        fontWeight: '400',
        color: '#a8a29e',
      },
      '.CodeInput': {
        backgroundColor: '#f5f5f4',
        border: '1px solid #e7e5e4',
        fontFamily: 'Ringside Regular Space, Ringside Regular, sans-serif',
      },
      '.Tab': {
        border: '1px solid #d6d3d1',
        borderRadius: '8px',
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        color: '#78716c',
        border: '2.5px solid #a8a29e',
        backgroundColor: '#fafaf9',
        boxShadow:
          '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
    },
  },
}
