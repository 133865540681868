<script setup lang="ts">
  import { watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useCurrentUser } from 'vuefire'

  const user = useCurrentUser()
  const router = useRouter()
  const route = useRoute()

  watch(user, async (currentUser, previousUser) => {
    if (!currentUser && previousUser && route.meta.requiresAuth) {
      return router.push({ name: 'login' })
    }
    if (currentUser && typeof route.query.redirect === 'string') {
      return router.push(route.query.redirect)
    }
  })
</script>

<template class="relative w-full text-pretty">
  <router-view v-slot="{ Component }">
    <FadeTransition>
      <component :is="Component" />
    </FadeTransition>
  </router-view>
</template>

<style>
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: baseline;
    font-weight: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
    border: 0 none;
    outline: 0;
    padding: 0;
    margin: 0;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    @apply bg-white
    text-stone-500 font-ringside;
  }
</style>
