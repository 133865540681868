import { ref, onMounted } from 'vue'
import { useFirebaseAuth } from 'vuefire'
import type { Ref } from 'vue'

interface UseAwakenRole {
  hasAwakenRole: Ref<boolean>
  refreshAwakenRoleStatus: () => Promise<boolean>
}

// State shared across all instances
const hasAwakenRole = ref(false)

export function useAwakenRole(): UseAwakenRole {
  const auth = useFirebaseAuth()

  const checkCurrentClaims = async (): Promise<boolean> => {
    const user = auth?.currentUser
    if (!user) {
      hasAwakenRole.value = false
      return false
    }

    const idTokenResult = await user.getIdTokenResult()

    const permits = idTokenResult.claims.permits as string[] | undefined

    hasAwakenRole.value = permits?.includes('AWAKEN') ?? false
    return hasAwakenRole.value
  }

  const refreshAwakenRoleStatus = async (): Promise<boolean> => {
    try {
      const user = auth?.currentUser
      if (!user) {
        hasAwakenRole.value = false
        return false
      }

      // Force token refresh to ensure we have the latest claims
      await user.getIdToken(true)
      return checkCurrentClaims()
    } catch (error) {
      console.error('useAwakenRole - Error refreshing role status:', error)
      hasAwakenRole.value = false
      return false
    }
  }

  onMounted(() => {
    // Check claims when auth state changes
    const unsubscribe = auth?.onAuthStateChanged(async (user) => {
      if (user) {
        await checkCurrentClaims()
      } else {
        hasAwakenRole.value = false
      }
    })

    // Cleanup subscription
    return () => {
      unsubscribe?.()
    }
  })

  return {
    hasAwakenRole,
    refreshAwakenRoleStatus,
  }
}
