<script setup lang="ts"></script>
<template>
  <div class="w-12 stroke-[11] mx-auto mt-10">
    <IconNewLogo></IconNewLogo>
  </div>
  <div class="flex flex-col mt-5 pb-16 border-b border-solid border-stone-300">
    <div class="flex gap-5 pb-1 font-bold text-xs">
      <div class="flex-1 text-right">HEADSPACE</div>
      <div class="flex-1">BODYSPACE</div>
    </div>
    <div class="flex gap-5 pb-2.5 lg:px-10 text-xs text-stone-500">
      <div class="flex-1 text-right">
        A clear state of mind centered in the present moment — awakening curiosity, creativity and
        connection.
      </div>
      <div class="flex-1">
        A grounded state of embodiment with elevated emotional attunement and physical sensations.
      </div>
    </div>
    <div class="flex gap-5 lg:px-10 text-stone-400 text-xxs md:text-xs font-ringside-narrow italic">
      <div class="flex-1 text-right">
        open, present, focused, social, states of flow, uplifting, gentle euphoria
      </div>
      <div class="flex-1">exploration, introspection, decompression, invigorating euphoria</div>
    </div>
  </div>
</template>
