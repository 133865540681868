import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LiquidProductView from '@/views/LiquidProductView.vue'
import ResetView from '@/views/ResetView.vue'
import TabsProductView from '@/views/TabsProductView.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import DonationView from '@/views/DonationView.vue'
import LegalView from '@/views/LegalView.vue'
import AuthenticationView from '@/views/AuthenticationView.vue'
import MagicLinkView from '@/views/MagicLinkView.vue'
import { useAwakenRole } from '@/utilities/useAwakenRole'
import { useMemberRecordComplete } from '@/utilities/useMemberRecordComplete'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: true },
    },
    {
      path: '/awaken/liquid',
      name: 'liquid',
      component: LiquidProductView,
      meta: { requiresAuth: true },
    },
    {
      path: '/awaken/tabs',
      name: 'tabs',
      component: TabsProductView,
      meta: { requiresAuth: true },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: CheckoutView,
      meta: { requiresAuth: true },
    },
    {
      path: '/donations',
      name: 'donations',
      component: DonationView,
      meta: { requiresAuth: true },
    },
    {
      path: '/legal',
      name: 'legal',
      component: LegalView,
    },
    {
      path: '/login',
      name: 'login',
      component: AuthenticationView,
    },
    {
      path: '/m/:magicLink',
      name: 'memberMagicLink',
      component: MagicLinkView,
      props: true,
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetView,
    },
    { path: '/liquid', redirect: { name: 'liquid' } },
    { path: '/tabs', redirect: { name: 'tabs' } },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'home' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (!to) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ top: 0 }) // Scroll to top smoothly
        }, 100) // Add a slight delay for animation (optional)
      })
    }
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth) {
    const { hasAwakenRole, refreshAwakenRoleStatus } = useAwakenRole()
    const { hasCompleteMemberRecord } = useMemberRecordComplete()

    // Only refresh if we don't have the role
    if (!hasAwakenRole.value) {
      await refreshAwakenRoleStatus()
    }

    if (!hasAwakenRole.value || !hasCompleteMemberRecord.value) {
      return {
        path: '/login',
        query: { redirect: to.fullPath },
      }
    }
  }
  return true
})
export default router
