<script setup lang="ts"></script>
<template>
  <CalloutBlock title="Product Integrity">
    <template #statement
      >We uphold the highest standards for our products ensuring your safety and peace of mind.
    </template>
    <template #points>
      <CalloutIconPoint
        title="Guaranteed Freshness"
        statement="Rest assured knowing your materials were harvested, tested, and formulated within the last 15 days for peak alkaloid concentration and freshness.">
        <IconFreshnessBadge class="w-10"></IconFreshnessBadge>
      </CalloutIconPoint>
      <CalloutIconPoint
        title="Vegan, Organic, Gluten-free"
        statement="All mushrooms are derived from brown rice, coconut husk fiber, and filtered water making it gluten-free (no rye, wheat, or soy), synthetic-free, and completely natural.">
        <IconNaturalBadge class="w-10"></IconNaturalBadge>
      </CalloutIconPoint>
      <CalloutIconPoint
        title="Comprehensive Testing"
        statement="We rigorously test each batch for potency to ensure precise formulations. Additionally, we screen for contaminants such as heavy metals (including bioaccumulated mercury and asbestos), pathogens, allergens, and adulterants.">
        <IconTestedBadge class="w-10"></IconTestedBadge>
      </CalloutIconPoint>
    </template>
  </CalloutBlock>
</template>
<style scoped></style>
