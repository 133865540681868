<script setup lang="ts">
  import { useCartStore } from '@/stores/cartStore'
  import { getAuth, signInWithCustomToken } from 'firebase/auth'
  import { getFunctions, httpsCallable } from 'firebase/functions'
  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const props = defineProps<{
    magicLink: string
  }>()

  const auth = getAuth()
  const router = useRouter()
  const cartStore = useCartStore()

  onMounted(async () => {
    if (!props.magicLink) {
      router.push('/')
    } else {
      const functions = getFunctions()
      const authorizeMagicLinkFunction = httpsCallable(functions, 'api-authorizeMagicLink')

      try {
        const result = await authorizeMagicLinkFunction({ magicLink: props.magicLink })
        console.log('Magic link authorized:', result.data)
        const data = result.data as { customToken: string }
        signInWithCustomToken(auth, data.customToken)
          .then((userCredential) => {
            const user = userCredential.user
            console.log('User signed in:', user)

            router.push('/')
          })
          .catch((error) => {
            console.error('Error signing in with custom token:', error)
          })
      } catch (error) {
        console.error('Error authorizing magic link:', error)
        router.push('/login')
      }
    }
  })
</script>
<template>
  <div class="flex flex-col min-h-screen justify-between">
    <div
      id="message-bar"
      class="p-2.5 bg-stone-700 text-center text-xxs md:text-xs text-stone-50 w-full">
      Member Access Link: {{ magicLink }}
    </div>
    <div class="flex flex-col py-10 mx-auto sm:px-16 w-80 sm:w-[31rem]">
      <FadeTransition>
        <div class="flex flex-col">
          <div class="text-lg text-center">
            One moment please<span class="ellipsisWave">.</span>
            <span class="ellipsisWave ellipsisWave-delay-300">.</span>
            <span class="ellipsisWave ellipsisWave-delay-600">.</span>
          </div>
        </div>
      </FadeTransition>
    </div>
    <Footer></Footer>
  </div>
</template>
<style scoped>
  .ellipsisWave {
    animation: wave 900ms linear infinite;
  }
  .ellipsisWave-delay-300 {
    animation-delay: 300ms;
  }

  .ellipsisWave-delay-600 {
    animation-delay: 600ms;
  }
  @keyframes wave {
    0% {
      color: #fafaf9;
    } /* stone-50 */
    25% {
      color: #f5f5f4;
    } /* stone-100 */
    50% {
      color: #e7e5e4;
    } /* stone-200 */
    75% {
      color: #d6d3d1;
    } /* stone-300 */
    100% {
      color: #a8a29e;
    } /* stone-400 */
  }
</style>
