<script setup lang="ts"></script>
<template>
  <ProductView productImage="liquid">
    <template #purchaseBlock>
      <PurchaseBlock initialSku="AL00">
        <template #productTitle> <span class="font-bold">AWAKEN</span> Liquid </template>
        <template #oneLineDescription>A liquid extract intended for daily use.</template>
        <template #activeAwareness
          >A comfortable, effective dose that helps you attune with your thoughts, emotions, and physical sensations. By
          supporting neuroplasticity, this daily liquid facilitates long-term changes in brain function — fostering more
          mindful living and a greater sense of well-being.</template
        >
        <template #ingredients
          >Clarity <span class="subtext">(formulated extract derived from organic fruitbodies)</span>, ethyl alcohol
          <span class="subtext">(carrier solvent)</span>, vegetable glycerin
          <span class="subtext">(flavor buffer)</span>, organic lemon oil <span class="subtext">(flavoring)</span>,
          organic orange oil <span class="subtext">(flavoring)</span>, monk fruit extract
          <span class="subtext">(natural noncaloric sweetener)</span>, organic anise oil
          <span class="subtext">(flavoring)</span>
        </template>
        <template #servingsPer>30 doses per bottle</template>
        <template #supply>6 week supply</template>
      </PurchaseBlock>
    </template>
    <template #features>
      <FeatureBlock
        title="More Effective than a Microdose"
        description="A gentle time-release dose without sensory distortion or cognitive impairment.">
        <template #icon><div class="font-black text-stone-300 text-5xl lg:text-[3.5rem] mr-3">4x</div></template>
      </FeatureBlock>
      <FeatureBlock
        title="Diverse Genetic Composition"
        description="Multiple species and strains are blended to create an optimal alkaloid profile.">
        <template #icon
          ><div class="mr-3 w-14">
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-stone-300">
              <g fill="none">
                <g
                  stroke-width="1.75"
                  fill="none"
                  stroke-linejoin="round">
                  <path
                    stroke-linecap="square"
                    d="M6.6 3c0 9 10.8 9 10.8 18" />
                  <path
                    stroke-linecap="square"
                    d="M17.4 3c0 9-10.8 9-10.8 18" />
                  <path d="M6.64 3.9h10.72" />
                  <path d="M7.74 7.5h8.52" />
                  <path d="M17.36 20.1H6.64" />
                  <path d="M16.2 16.5H7.74" />
                </g>
              </g>
            </svg></div
        ></template>
      </FeatureBlock>
    </template>
    <template #sidebar>
      <div
        id="instructions"
        class="order-[2] sidebar flex flex-col bg-stone-200 p-10 rounded-lg">
        <div class="pb-3 font-bold uppercase">Dosage</div>
        <div class="text-sm pr-1">
          Place 1 ml (full dropper) under your tongue and swallow. Allow a few moments for absorption into the mouth &
          throat before eating or drinking.
        </div>
        <div class="pb-3 pt-10 font-bold uppercase">Frequency</div>
        <div class="text-sm">Use daily for five days followed by two days of rest for integration.</div>
        <div class="flex p-4 justify-center gap-3">
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-white"></div>
          <div class="grow-0 rounded-full p-2 bg-white"></div>
        </div>
        <div class="text-sm">Rest days may be scheduled to accommodate your routine.</div>
        <div class="flex p-4 justify-center gap-3">
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>

          <div class="grow-0 rounded-full p-2 bg-white"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-white"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
          <div class="grow-0 rounded-full p-2 bg-stone-500"></div>
        </div>
      </div>
      <AccurateDose
        dose="8"
        class="order-[5]"></AccurateDose>
      <OurGeneticLibrary class="order-[7]"></OurGeneticLibrary>
      <EnvironmentalStewardship class="order-[8]"></EnvironmentalStewardship>
      <ProductIntegrity class="order-[2]"></ProductIntegrity>
    </template>
    <template #content
      ><StoryBlock
        class="order-[1]"
        subtitle="The Perfect Dose"
        statement="Feel open, present, and connected with a daily ritual that supports continuous, incremental change.">
        <StoryPoint
          title="Emotional Balance"
          statement="Support whole-body nervous system regulation to unburden stress and ease the underlying causes of anxiety and depression.">
        </StoryPoint>
        <StoryPoint
          title="Focus and Clarity"
          statement="Improve executive function, stimulate creativity, and increase your resilience to 
               overwhelm.">
        </StoryPoint>
        <StoryPoint
          title="Neurodiversity Spectrum Support"
          statement="Amplify your inherent cognitive abilities while easing the challenges of daily life. (ADHD, AuDHD, ASD, 2E, CDI/Giftedness)">
        </StoryPoint>
      </StoryBlock>
      <StoryBlock
        class="order-[3]"
        subtitle="Direct Metabolism"
        statement="This liquid format absorbs quickly for a smooth sustained presence for up to 6 hours. ">
        <StoryPoint
          title="More Effective than a Microdose"
          statement="Microdoses are often under-dosed and too short-acting to be effective."></StoryPoint>
        <StoryPoint
          title="No Sensory Distortion or Cognitive Impairment"
          statement="This Headspace alkaloid profile keeps you present and in control."></StoryPoint>
        <div class="mt-5">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 221.1 85"
            xml:space="preserve"
            class="text-stone-500 font-ringside font-bold text-[6px] pt-5 lg:px-5"
            style="stroke-miterlimit: 10">
            <g>
              <g class="stroke-stone-300 fill-none stroke-[0.5]">
                <line
                  x1="44.3"
                  y1="6.1"
                  x2="44.3"
                  y2="65.7" />
                <line
                  x1="88.5"
                  y1="65.7"
                  x2="88.5"
                  y2="40.4" />
                <line
                  x1="132.8"
                  y1="50.1"
                  x2="132.8"
                  y2="65.7" />
                <line
                  x1="177"
                  y1="56.1"
                  x2="177"
                  y2="65.7" />
              </g>
              <g
                id="Tabs_Line"
                class="stroke-stone-500 fill-none stroke-[0.5]"
                style="stroke-linecap: round">
                <g>
                  <path d="M0,65.7c.2-.5.4-.9.6-1.4" />
                  <path
                    d="M2.2,60.7c49.9-109.8,43.1-24.1,123.4-4.1,12.8,3.2,74,7.5,92,7.7"
                    style="stroke-dasharray: 2.9712, 3.9616" />
                  <path d="M219.6,64.3c.6,0,1.1,0,1.5,0" />
                </g>
              </g>
              <path
                id="Liquid_Line"
                d="M220.8,58.7C128.2,58.7,29.7,16.4,0,65.7"
                class="stroke-stone-500 fill-none" />
              <g>
                <line
                  x1="140.1"
                  y1="21.2"
                  x2="152.7"
                  y2="21.2"
                  class="stroke-stone-500" />
                <text
                  transform="translate(159.1 22.7)"
                  class="fill-stone-500">
                  <tspan class="tracking-tight">AWAKEN&nbsp;</tspan>
                  <tspan class="font-medium">Liquid</tspan>
                </text>
              </g>
              <g>
                <g class="stroke-stone-400">
                  <g>
                    <line
                      x1="140.1"
                      y1="10.3"
                      x2="142.1"
                      y2="10.3" />
                    <line
                      x1="144.7"
                      y1="10.3"
                      x2="149.4"
                      y2="10.3"
                      style="stroke-dasharray: 2.9084, 2.1813" />
                    <line
                      x1="150.7"
                      y1="10.3"
                      x2="152.7"
                      y2="10.3" />
                  </g>
                </g>
                <text
                  transform="translate(159.1 12.1)"
                  class="fill-stone-400 text-[5.5px]">
                  <tspan class="tracking-tight">AWAKEN&nbsp;</tspan>
                  <tspan class="font-medium">Tabs</tspan>
                </text>
              </g>
              <g class="fill-stone-400">
                <text transform="translate(.2 76.7)">0</text>
                <text transform="translate(29.4 76.7)">1HR</text>
                <text transform="translate(72.2 76.7)">2HR</text>
                <text transform="translate(116.4 76.7)">3HR</text>
                <text transform="translate(160.5 76.7)">4HR</text>
                <text transform="translate(204.7 76.7)">5HR</text>
              </g>
              <g>
                <line
                  x1="140.1"
                  y1="31.8"
                  x2="152.7"
                  y2="31.8"
                  style="fill: none; stroke: #bcbec0; stroke-width: 3; stroke-miterlimit: 10" />
                <text
                  transform="translate(159.1 33.6)"
                  class="fill-stone-500 font-medium">
                  Microdose
                </text>
              </g>
            </g>
            <g id="Layer_2">
              <path
                d="M0,65.7c64.7-14.5,55.1,0,106.3,0H0Z"
                style="fill: #bcbec0" />
              <line
                x1="221"
                y1="65.6"
                x2=".1"
                y2="65.6"
                style="fill: none; stroke: #bcbec0; stroke-width: 0.75; stroke-miterlimit: 10" />
            </g>
          </svg>
        </div>
      </StoryBlock>
      <StoryBlock
        class="order-[4]"
        subtitle="Daily Headspace"
        statement="Formulated with an alkaloid profile that prioritizes mental clarity 
          over physical effects, making it ideal for daily activities.
">
        <SpaceDiagram></SpaceDiagram>
      </StoryBlock>
      <StoryBlock
        class="order-last"
        subtitle="Compendium"
        statement="Frequently asked questions and other useful information">
        <StoryPoint
          title="When should I take AWAKEN Liquid?"
          statement="For best results, take it within the first hour after waking while your mind is calm and quiet.">
        </StoryPoint>
        <StoryPoint
          title="What are the storage conditions & shelf life?"
          statement="For optimal potency, store in a cool, dark place and use within 4 months. Refrigerate for maximum freshness but do not freeze. Heat, light, and moisture will rapidly degrade all types of mushroom products due to dephosphorylation and oxidation."></StoryPoint>
        <StoryPoint
          title="How many grams of mushrooms are in each bottle? "
          statement="This concentrated liquid contains the active equivalent of about 30 grams of average fruitbodies. We do not recommend using dry weight as a measure for dosing purposes as the amount of active alkaloids can vary significantly."></StoryPoint>
        <StoryPoint
          title="What if I don't feel anything?"
          statement="Social and environmental stimulation may dilute the perceived sensations but the product is working. Sensitivity will increase with regular use as directed."></StoryPoint>
        <StoryPoint
          title="Can I take more?"
          statement="For larger or repeated dosing, we recommend our other product: AWAKEN Tabs."></StoryPoint>
        <StoryPoint
          title="Will this affect my ability to perform daily activities safely?"
          statement="When taken as directed, AWAKEN Liquid does not cause sensory distortion or cognitive impairment for most members. Those who are particularly sensitive should start with a lower dose at home to assess their individual response.">
        </StoryPoint>
        <StoryPoint
          title="Does this contain cannabinoids, amanita, or synthetic analogs? "
          statement="No. All products are 100% naturally derived from our genetic library with no added psychoactives or synthetics including THC, CBD, synthetic cannabinoids, amanita muscaria, or 4-AcO-DMT (synthetic psilocybin).">
        </StoryPoint>
      </StoryBlock>
    </template>
  </ProductView>
</template>

<style scoped>
  .subtext {
    @apply text-stone-400;
  }
</style>
