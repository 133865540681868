<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useCatalogStore } from '@/stores/catalogStore'
  import { useCartStore } from '@/stores/cartStore'

  const props = defineProps<{
    sku: string
    quantity: number
  }>()

  const { findProductBySku } = useCatalogStore()
  const { cart } = storeToRefs(useCartStore())
  const { updateQuantity } = useCartStore()

  const item = computed(() => {
    const product = findProductBySku(props.sku)
    return product || null 
  })
  const itemImage = computed(() => {
    return item.value?.name === 'Liquid' ? 'bg-liquid' : 'bg-tabs' 
  })
  const productPage = computed(() => {
    return item.value?.name === 'Liquid' ? '/awaken/liquid' : '/awaken/tabs'
  })

  const selectedQuantity = ref(props.quantity)
  const hideIfZeroQty = computed(() => (selectedQuantity.value === 0 ? 'hidden' : ''))

  // sonarlint:ignore TypeScript:S4030
  const quantityOptions: number[] = [0]
  for (let i = 1; i <= 20; i++) {
    quantityOptions.push(i)
  }

  const handleQuantityChange = () => {
    updateQuantity(props.sku, selectedQuantity.value)
  }
</script>
<template>
  <div
    :class="hideIfZeroQty"
    class="flex items-center py-5 text-sm">
    <div
      @click="$router.push(productPage)"
      :class="itemImage"
      class="w-10 aspect-square bg-cover bg-no-repeat bg-bottom mr-2.5 cursor-pointer"></div>

    <div
      v-if="item"
      @click="$router.push(productPage)"
      class="flex-1 flex flex-col self-start pt-1 cursor-pointer">
      <div class="flex-1 font-bold text-">
        <span class="uppercase font-bold tracking-tight pr-1">{{ item.brand }}</span
        >{{ item.name }}
      </div>
      <div class="flex-1 text-xs">
        <template v-if="item?.variation"
          >{{ item.variation[0] }}
          <template v-if="item.variation[1]">
            <span class="text-stone-00 font-thin px-1">◈</span>
            Mode {{ item.variation[1] }}</template
          >
        </template>
      </div>
    </div>

    <div class="flex-1 flex flex-row-reverse md:flex-row gap-5 md:gap-0">
      <div class="group md:flex text-center md:text-left">
        <select
          v-model="selectedQuantity"
          @change="handleQuantityChange"
          class="py-2 pl-4 pr-10 text-left text-stone-500 appearance-none rounded-lg bg-transparent bg-downChevron bg-no-repeat bg-[90%_50%] bg-40% border border-solid border-stone-200 cursor-pointer">
          <option
            v-for="quantity in quantityOptions"
            :key="quantity"
            :value="quantity">
            {{ quantity }}
          </option>
        </select>
      </div>
      <div
        v-if="item"
        class="flex-1 self-center text-right text-base font-ringside-narrow font- text-stone-400">
        {{
          (item.price * cart[sku]).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        }}
      </div>
    </div>
  </div>
</template>
