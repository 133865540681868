<script setup lang="ts">
  import StoryPoint from '@/components/layout/forView/ProductView/StoryPoint.vue'
  import { analytics } from '@/firebase'
  import { useCatalogStore } from '@/stores/catalogStore'
  import { useAnalyticsItemGenerators } from '@/utilities/analytics/AnalyticsItemGenerators'
  import { logEvent, type Item } from 'firebase/analytics'
  import { storeToRefs } from 'pinia'
  import { computed, onBeforeMount } from 'vue'

  const { catalog } = storeToRefs(useCatalogStore())
  const { generateAnalyticsItem } = useAnalyticsItemGenerators()

  const analyticsItems = computed((): Array<Item> => {
    return catalog.value
      .filter((item) => item.sku !== 'AL00')
      .map((item) => generateAnalyticsItem(item.sku))
      .filter((item): item is Item => item !== null)
  })

  console.log('TabsProductView - analyticsItems', analyticsItems)
  onBeforeMount(() => {
    if (analyticsItems.value) {
      logEvent(analytics, 'view_item_list', {
        item_list_id: 'hero',
        item_list_name: 'Hero Product Selection',
        items: analyticsItems.value,
      })
    }
  })
</script>

<template>
  <ProductView productImage="tabs">
    <template #purchaseBlock>
      <PurchaseBlock initialSku="AT0A">
        <template #productTitle> <span class="font-bold">AWAKEN</span> Tabs </template>
        <template #oneLineDescription>Rapid dissolving tabs for routine self-care.</template>
        <template #activeAwareness
          >A comfortable, configurable dose that helps you attune with your thoughts, emotions, and physical sensations.
          As a weekly reset or a monthly retreat, these tabs provide a range of experiences designed to facilitate
          exploration, introspection, and decompression.
        </template>
        <template #ingredients
          >Clarity <span class="subtext">(formulated powder of organic mushroom fruitbodies)</span>, cellulose
          <span class="subtext">(from sugarcane, binder)</span>, citric acid
          <span class="subtext">(from citrus, metabolic synergist)</span>, ascorbic acid
          <span class="subtext">(from citrus, antioxidant)</span>, α-Tocopherol
          <span class="subtext">(from sunflowers, antioxidant)</span>
        </template>
        <template #servingsPer>12 tabs per tube</template>
        <template #supply>6 weekly resets</template>
      </PurchaseBlock>
    </template>
    <template #features>
      <FeatureBlock
        title="A Spectrum of Experiences"
        description="Six formulations provide a variety of choices to fit your desired intention.">
        <template #icon
          ><div
            class="spectrum w-11 h-11 relative top-2 lg:top-2 rounded-full mx-3 border- border-solid border-stone-300"></div
        ></template>
      </FeatureBlock>
      <FeatureBlock
        title="Diverse Genetic Composition"
        description="Multiple species and strains are blended to create an optimal alkaloid profile.">
        <template #icon
          ><div class="ml-1 mr-2 w-14">
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-stone-300">
              <g fill="none">
                <g
                  stroke-width="1.75"
                  fill="none"
                  stroke-linejoin="round">
                  <path
                    stroke-linecap="square"
                    d="M6.6 3c0 9 10.8 9 10.8 18" />
                  <path
                    stroke-linecap="square"
                    d="M17.4 3c0 9-10.8 9-10.8 18" />
                  <path d="M6.64 3.9h10.72" />
                  <path d="M7.74 7.5h8.52" />
                  <path d="M17.36 20.1H6.64" />
                  <path d="M16.2 16.5H7.74" />
                </g>
              </g>
            </svg></div
        ></template>
      </FeatureBlock>
    </template>
    <template #sidebar>
      <div
        id="instructions"
        class="order-[2] lg:w-[calc(944px*.4)] flex flex-col bg-stone-200 p-10 rounded-lg">
        <div class="pb-3 font-bold uppercase">DOSE & FREQUENCY</div>
        <div class="text-sm pr-1 pb-5">
          Dosage varies per individual. Begin with 1 - 2 tabs by mouth with water. Wait one hour before considering more
          • Eating may delay onset • High levels of social or sensory stimulation may necessitate a larger dose.
        </div>
        <div class="flex gap-2.5 lg:gap-5 self-center">
          <div class="">
            <div class="flex py-1 gap-1.5 justify-end">
              <IconCircle class="bg-white"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
            </div>
            <div class="flex py-2 gap-1.5 justify-end">
              <IconCircle class="bg-white"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
            </div>
            <div class="flex py-1 gap-1.5 justify-end">
              <IconCircle class="bg-stone-500"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
              <IconCircle class="bg-stone-500"></IconCircle>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-between text-xs md:text-sm py-0.5">
            <div class="">
              <span class="font-medium tracking-tight">Weekly Reset</span
              ><span class="pl-2 md:text-xs font-ringside-narrow">(1-2)</span>
            </div>
            <div class="">
              <span class="font-medium tracking-tight">Monthly Retreat</span
              ><span class="pl-2 md:text-xs font-ringside-narrow">(2-3)</span>
            </div>
            <div class="md:relative md:top-[1px]">
              <span class="font-medium tracking-tight">Traditional</span
              ><span class="pl-2 md:text-xs font-ringside-narrow">(4+)</span>
            </div>
          </div>
        </div>

        <div class="pb-3 pt-10 font-bold uppercase relative">
          A BASIC RESET
          <WomanWalking class="fill-stone-500 w-12 inline-block ml-10"></WomanWalking>
        </div>
        <div class="text-sm">
          A simple walk in nature or a stroll through a quiet neighborhood once or twice a week with your choice of tabs
          can be an ideal way to reset and reconnect.
        </div>
        <div class="text-sm pt-2.5">
          It provides changes in scenery, shifts in temperature, and repetitive, rhythmic movement
          <span class="text-stone-400">(bilateral stimulation)</span> - all contributing to a calm, regulated nervous
          system.
        </div>
      </div>
      <AccurateDose
        dose="5"
        class="order-[5]"></AccurateDose>
      <OurGeneticLibrary class="order-[7]"></OurGeneticLibrary>
      <EnvironmentalStewardship class="order-[8]"></EnvironmentalStewardship>
      <ProductIntegrity class="order-[2]"></ProductIntegrity>
    </template>
    <template #content>
      <StoryBlock
        class="order-[1]"
        subtitle="The Perfect Dose"
        statement="A curious, playful approach to routine self-care — tailored to your available space, time commitment, and desired intention.">
        <StoryPoint
          title="Comfortable"
          statement="A smooth, predictable experience."></StoryPoint>
        <StoryPoint
          title="Consistent"
          statement="A standardized concentration and composition of active alkaloids.">
        </StoryPoint>
      </StoryBlock>

      <StoryBlock
        class="order-[3]"
        subtitle="Choose your Space"
        statement="A spectrum of personalized experiences for your mind and body. Each path offers a sense of curiosity, connection, and attunement.">
        <SpaceDiagram></SpaceDiagram>
      </StoryBlock>
      <StoryBlock
        class="order-[4]"
        subtitle="Select a Mode"
        statement="Adjust the time and perceived intensity of the experience to fit your desired intention using a natural citrus antioxidant.">
        <StoryPoint
          title="Mode A"
          statement="A traditional experience with a gentle onset and longer duration.">
        </StoryPoint>
        <StoryPoint
          title="Mode B"
          statement="A faster, more intense onset with a shorter duration. Feel more or take less.">
        </StoryPoint>
        <div class="mt-5">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 221.1 85"
            xml:space="preserve"
            class="text-stone-500 font-ringside font-bold text-[6px] pt-5 stroke-stone-400 fill-none lg:stroke-[.75px] lg:px-5"
            style="stroke-miterlimit: 10">
            <g
              id="Vertical_Chart_Lines"
              class="stroke-stone-200">
              <line
                x1="44.2"
                y1="8"
                x2="44.2"
                y2="67.6" />
              <line
                x1="88.4"
                y1="35.8"
                x2="88.4"
                y2="67.6" />
              <line
                x1="132.7"
                y1="48.3"
                x2="132.7"
                y2="67.6" />
              <line
                x1="176.9"
                y1="60"
                x2="176.9"
                y2="67.6" />
            </g>

            <path
              id="ModeA_Line"
              d="M0,67.6c.2-.5.4-.9.6-1.4 M2.2,62.6c49.8-109.7,43-24.1,123.3-4.1,12.8,3.2,74,7.5,91.9,7.7"
              stroke-linecap="round"
              stroke-dasharray="3,4" />

            <path
              id="ModeB_Line"
              d="M221.1,65c-92.6,0-180.4-77.1-221.1,2.5" />
            <line
              class="st0"
              x1="150.6"
              y1="13.7"
              x2="166.3"
              y2="13.7" />

            <path
              id="ModeB_Legend"
              d="M150.6 26.6h15.7"
              stroke-dasharray="4,3"
              stroke-dashoffset="1.25" />

            <g class="stroke-none fill-stone-400 font-bold">
              <text
                transform="translate(174.4 16)"
                class="text-[7px]">
                MODE A
              </text>
              <text
                transform="translate(174.4 28.8)"
                class="text-[7px]">
                MODE B
              </text>
            </g>
            <g class="stroke-none fill-stone-400">
              <text transform="translate(.2 78.6)">0</text>
              <text transform="translate(29.4 78.6)">1HR</text>
              <text transform="translate(72.2 78.6)">2HR</text>
              <text transform="translate(116.3 78.6)">3HR</text>
              <text transform="translate(160.4 78.6)">4HR</text>
              <text transform="translate(204.6 78.6)">5HR</text>
            </g>
          </svg>
        </div>
      </StoryBlock>

      <StoryBlock
        class="order-last"
        subtitle="Compendium"
        statement="Frequently asked questions and other useful information">
        <StoryPoint
          title="How many grams of mushrooms are in tabs?"
          statement="Each tube of tabs contains the active equivalent of about 5 grams of average fruitbodies. We do not recommend using dry weight as a measure for dosing purposes as the amount of active alkaloids can vary significantly.">
        </StoryPoint>
        <StoryPoint
          title="What are the storage conditions & shelf life?"
          statement="For optimal potency, store in a cool, dark place and use within 4 months. Refrigerate for maximum freshness but do not freeze. Heat, light, and moisture will rapidly degrade all types of mushroom products due to dephosphorylation and oxidation."></StoryPoint>
        <StoryPoint
          title="How long before I begin to feel the effects?"
          statement="As tabs dissolve, effects begin gradually within 15 minutes and subtly build to full intensity over about an hour. Effects may be delayed 1-2 hours if taken with food."></StoryPoint>
        <StoryPoint
          title="What activities pair well with tabs?"
          statement="Tabs enhance body-focused activities like exercise, dance, movement practices and bodywork/massage. They also complement creative expression, sensory experiences like nature walks, and intimate one-on-one connections."></StoryPoint>
        <StoryPoint
          title="Do tabs provide neurodiversity spectrum support?"
          statement="Yes, tabs are designed to enhance natural cognitive abilities while helping manage daily challenges for neurodiverse individuals (including ADHD, AuDHD, ASD, 2E, and CDI/Giftedness)."></StoryPoint>
        <StoryPoint
          title="Can I take an additional dose?"
          statement="Yes, it's safe to take additional doses to increase or extend your experience. However, for optimal results, wait at least 1 hour after the previous dose to accurately manage the intensity. Additional doses may need to be larger than your starting dose to maintain the same effect."></StoryPoint>
        <StoryPoint
          title="What if I don't feel anything?"
          statement="Individual response may vary. Food can delay onset an additional 1-2 hours.  Social and environmental stimulation may dilute your perceived sensations. You may need a larger dose for your particular set and setting. Sensitivity will increase with regular use as your interoception improves. "></StoryPoint>
        <StoryPoint
          title="Does this contain cannabinoids, amanita, or synthetic analogs? "
          statement="No. All products are 100% naturally derived from our genetic library with no added psychoactives or synthetics including THC, CBD, synthetic cannabinoids, amanita muscaria, or 4-AcO-DMT (synthetic psilocybin).">
        </StoryPoint>
      </StoryBlock>
    </template>
  </ProductView>
</template>

<style scoped>
  .spectrum {
    background: conic-gradient(#fafaf9, #f5f5f4, #e7e5e4, #d6d3d1, #a8a29e, #78716c);
  }
  .subtext {
    @apply text-stone-400;
  }
</style>
