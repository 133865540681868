<script setup lang="ts"></script>

<template>
  <div id="icon-logo" class="flex aspect-[50/40]">
    <div class="iridescent flex-1">
      <div class="bg-stone-500 w-full h-full opacity-50"></div>
    </div>
  </div>
</template>

<style scoped>
#icon-logo {
  mask-image: url(@/assets/images/logo.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
}
.iridescent {
  background: repeating-linear-gradient(
    300deg,
    #f9c8ce,
    #f9c8e0,
    #e0c8f9,
    #cfc8f9,
    #c8d3f9,
    #c8f9f9,
    #c8f9e1,
    #f9f7c8,
    #f9d1c8,
    #f9c8ce
  );
  background-repeat: repeat-x;
  background-size: 400% 100%;
  animation: gradient 15s linear infinite;
}
@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -33% 50%;
  }
}
</style>
