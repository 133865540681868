<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    id="icons"
    style="margin: 2px"
    viewBox="0 0 24 24">
    <path
      d="M7.5 9.6v7h9.1v-7"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1" />
    <path
      d="m6.3 10.4 5.7-4 5.7 4M13.7 16.6v-3.3c0-.6-.5-1.1-1.1-1.1h-1.1c-.6 0-1.1.5-1.1 1.1v3.3"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1" />
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      miter-limit="10" />
  </svg>
</template>
