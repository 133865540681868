import { useCartStore } from '@/stores/cartStore'
import { until } from '@vueuse/core'
import { storeToRefs } from 'pinia'

export async function initializeStripeCustomCheckoutElements() {
  const { stripeCustomCheckout } = storeToRefs(useCartStore())

  await until(() => stripeCustomCheckout.value !== undefined).toBeTruthy()
  if (stripeCustomCheckout.value?.getElement) {
    try {
      let addressElement = stripeCustomCheckout.value.getElement('address', 'shipping')
      let paymentElement = stripeCustomCheckout.value.getElement('payment')

      if (addressElement) {
        addressElement.unmount()
      } else {
        addressElement = stripeCustomCheckout.value.createElement('address', {
          mode: 'shipping',
        })
      }

      if (paymentElement) {
        paymentElement.unmount()
      } else {
        paymentElement = stripeCustomCheckout.value.createElement('payment', { layout: 'tabs' })
      }

      addressElement.mount('#address-element')
      paymentElement.mount('#payment-element')
    } catch (error) {
      console.error('Error initializing payment element:', error)
    }
  }
}
