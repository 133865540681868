<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 3"
    viewBox="0 0 24 24">
    <path
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M4.5 9H3M4.5 15H3M4 12H2M5.636 5.636a9 9 0 1 1 0 12.728" />
    <path
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="m15.5 14.634-3 1.732a1 1 0 0 1-1 0l-3-1.732a1 1 0 0 1-.5-.866v-3.536a1 1 0 0 1 .5-.866l3-1.732a1 1 0 0 1 1 0l3 1.732a1 1 0 0 1 .5.866v3.536a1 1 0 0 1-.5.866Z" />
    <path
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M15.866 9.732 12.5 11.675a1 1 0 0 1-1 0L8.134 9.732M12 16.5v-4.691" />
  </svg>
</template>
