import { useDonationStore } from '@/stores/donationStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export function useDonationDetails() {
  const { donationRecord } = storeToRefs(useDonationStore())

  const donationDetails = computed(() => {
    if (donationRecord.value.stripeCheckoutSession) {
      return {
        lineItems: donationRecord.value.stripeCheckoutSession.line_items.data,
        accountBalanceApplied: donationRecord.value.stripeCheckoutSession.total_details.amount_discount / 100,
        grandTotal: donationRecord.value.stripeCheckoutSession.amount_total / 100,
        shipping: donationRecord.value.stripeCheckoutSession.customer?.shipping || {},
      }
    }
    return {
      lineItems: [],
      accountBalanceApplied: 0,
      grandTotal: 0,
      shipping: {},
    }
  })

  return { donationDetails }
}
