import { useCartStore } from '@/stores/cartStore'
import { useCatalogStore } from '@/stores/catalogStore'
import type { Item } from 'firebase/analytics'
import { storeToRefs } from 'pinia'

export function useAnalyticsItemGenerators() {
  const catalogStore = useCatalogStore()
  const { catalog } = storeToRefs(catalogStore)
  const { findProductBySku } = catalogStore

  const cartStore = useCartStore()
  const { cart } = storeToRefs(cartStore)

  function generateAnalyticsItem(sku: string, qty?: number): Item | undefined {
    const catalogItem = findProductBySku(sku)
    if (catalogItem) {
      return {
        item_id: sku,
        item_name: catalogItem.name,
        item_brand: catalogItem.brand,
        item_variant: catalogItem.variation?.join(', '),
        quantity: qty ?? 1,
        price: catalogItem.price,
      }
    }
  }

  function generateAnalyticsItemsForName(name: string): Item[] {
    return catalog.value
      .filter((item) => item.name === name)
      .map((item) => generateAnalyticsItem(item.sku))
      .filter((item): item is Item => item !== undefined)
  }

  function generateAnalyticsItemsFromCart(): Item[] {
    if (!cart?.value) return []
    return Object.entries(cart.value)
      .filter(([_, quantity]) => quantity > 0)
      .map(([sku, quantity]) => generateAnalyticsItem(sku, quantity))
      .filter((item): item is Item => item !== undefined)
  }

  return {
    generateAnalyticsItem,
    generateAnalyticsItemsForName,
    generateAnalyticsItemsFromCart,
  }
}
