<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.7 56.7"
    style="fill: none; stroke: #ffffff; stroke-width: 1.5; stroke-linecap: round; stroke-linejoin: round"
    xml:space="preserve">
    <g>
      <path
        style="fill: #78716c"
        d="M55.3,28.3c0,1.9-4.2,3.3-4.6,5.1c-0.4,1.8,2.7,4.9,1.9,6.6c-0.8,1.7-5.2,1.2-6.3,2.6
           c-1.2,1.5,0.3,5.6-1.1,6.8c-1.5,1.2-5.2-1.2-6.9-0.4c-1.7,0.8-2.1,5.2-4,5.6c-1.8,0.4-4.1-3.3-6-3.3c-1.9,0-4.2,3.7-6,3.3
           c-1.8-0.4-2.3-4.8-4-5.6c-1.7-0.8-5.4,1.5-6.9,0.4c-1.5-1.2,0-5.3-1.1-6.8c-1.2-1.5-5.5-0.9-6.3-2.6c-0.8-1.7,2.3-4.7,1.9-6.6
           c-0.4-1.8-4.6-3.2-4.6-5.1c0-1.9,4.2-3.3,4.6-5.1c0.4-1.8-2.7-4.9-1.9-6.6c0.8-1.7,5.2-1.2,6.3-2.6c1.2-1.5-0.3-5.6,1.1-6.8
           c1.5-1.2,5.2,1.2,6.9,0.4c1.7-0.8,2.1-5.2,4-5.6c1.8-0.4,4.1,3.3,6,3.3c1.9,0,4.2-3.7,6-3.3c1.8,0.4,2.3,4.8,4,5.6
           c1.7,0.8,5.4-1.5,6.9-0.4c1.5,1.2,0,5.3,1.1,6.8c1.2,1.5,5.5,0.9,6.3,2.6c0.8,1.7-2.3,4.7-1.9,6.6C51.2,25,55.3,26.4,55.3,28.3z" />
    </g>
    <g>
      <path
        d="M27.2,19.1c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2c7.5,0,9.3-9.2,13.6-11.7C37.6,21.6,32.6,19.1,27.2,19.1z" />
      <path d="M28.3,27.3c-5.1,0.9-9.7,3.9-12.5,8.3" />
    </g>
  </svg>
</template>
