<script setup lang="ts">
  const props = defineProps<{
    sku: string
    quantity: number
    price: number
  }>()

  console.log('DonationItem - props', props)
  import { useCatalogStore } from '@/stores/catalogStore'
  import { computed } from 'vue'
  const { findProductBySku } = useCatalogStore()

  const item = computed(() => {
    const product = findProductBySku(props.sku)
    return product || null // Handle potential missing product
  })

  const itemImage = computed(() => {
    return item.value?.name === 'Liquid' ? 'bg-liquid' : 'bg-tabs' // Use optional chaining
  })

  const productPage = computed(() => {
    return item.value?.name === 'Liquid' ? '/awaken/liquid' : '/awaken/tabs' // Use optional chaining
  })
</script>
<template>
  <div class="flex items-center py-5 text-sm border-b border-solid border-stone-400">
    <div
      :class="itemImage"
      class="w-10 aspect-square bg-cover bg-no-repeat bg-bottom mr-2.5 cursor-pointer"></div>

    <div
      v-if="item"
      class="flex-2 flex flex-col self-start pt-1 cursor-pointer">
      <div class="flex-1 font-bold text-">
        <span class="uppercase font-bold tracking-tight pr-1">{{ item.brand }}</span
        >{{ item.name }}
      </div>
      <div class="flex-1 text-xs">
        <template v-if="item?.variation"
          >{{ item.variation[0] }}
          <template v-if="item.variation[1]">
            <span class="text-stone-00 font-thin px-1">◈</span>
            Mode {{ item.variation[1] }}</template
          >
        </template>
      </div>
    </div>

    <div
      v-if="item"
      class="flex-1 text-center">
      {{ quantity }}
    </div>

    <div class="flex-1 flex flex-row-reverse md:flex-row gap-5 md:gap-0">
      <div
        v-if="item"
        class="flex-1 self-center text-right text-base font-ringside-narrow font- text-stone-400">
        {{
          (price / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        }}
      </div>
    </div>
  </div>
</template>
