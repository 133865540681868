<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.7 56.7"
    style="fill: none; stroke: #ffffff; stroke-width: 1.5; stroke-linecap: round; stroke-linejoin: round"
    xml:space="preserve">
    <path
      style="fill: #78716c"
      d="M55.3,28.3c0,1.9-4.2,3.3-4.6,5.1c-0.4,1.8,2.7,4.9,1.9,6.6c-0.8,1.7-5.2,1.2-6.3,2.6
				c-1.2,1.5,0.3,5.6-1.1,6.8c-1.5,1.2-5.2-1.2-6.9-0.4c-1.7,0.8-2.1,5.2-4,5.6c-1.8,0.4-4.1-3.3-6-3.3c-1.9,0-4.2,3.7-6,3.3
				c-1.8-0.4-2.3-4.8-4-5.6c-1.7-0.8-5.4,1.5-6.9,0.4c-1.5-1.2,0-5.3-1.1-6.8c-1.2-1.5-5.5-0.9-6.3-2.6c-0.8-1.7,2.3-4.7,1.9-6.6
				c-0.4-1.8-4.6-3.2-4.6-5.1c0-1.9,4.2-3.3,4.6-5.1c0.4-1.8-2.7-4.9-1.9-6.6c0.8-1.7,5.2-1.2,6.3-2.6c1.2-1.5-0.3-5.6,1.1-6.8
				c1.5-1.2,5.2,1.2,6.9,0.4c1.7-0.8,2.1-5.2,4-5.6c1.8-0.4,4.1,3.3,6,3.3c1.9,0,4.2-3.7,6-3.3c1.8,0.4,2.3,4.8,4,5.6
				c1.7,0.8,5.4-1.5,6.9-0.4c1.5,1.2,0,5.3,1.1,6.8c1.2,1.5,5.5,0.9,6.3,2.6c0.8,1.7-2.3,4.7-1.9,6.6C51.2,25,55.3,26.4,55.3,28.3z" />

    <path
      class="st1"
      d="M39.7,23.9H17" />
    <path
      class="st1"
      d="M23.3,16.2v2.8" />
    <path
      class="st1"
      d="M33.4,16.2v2.8" />
    <path
      class="st1"
      d="M35.9,39.1H20.8c-2.1,0-3.8-1.7-3.8-3.8V21.4c0-2.1,1.7-3.8,3.8-3.8h15.1c2.1,0,3.8,1.7,3.8,3.8v13.9
				C39.7,37.4,38,39.1,35.9,39.1z" />
    <path
      class="st1"
      d="M23.5,34.6h3.4" />
    <path
      class="st1"
      d="M25.1,34.6v-6.3L23.3,30" />
    <path
      class="st1"
      d="M29.5,33.8c0.3,0.5,0.9,0.9,1.6,0.9c1.1,0,1.9-0.9,1.9-1.9c0-0.5-0.2-0.9-0.6-1.2c-0.3-0.2-0.6-0.4-1-0.4
				h-1.7l0.3-2.8h2.7" />
  </svg>
</template>
