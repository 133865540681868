<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <g fill="none">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
        d="M12 21v0a9 9 0 0 1-9-9v0a9 9 0 0 1 9-9v0a9 9 0 0 1 9 9v0a9 9 0 0 1-9 9Z" />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
        d="m16 10-5 5-3-3" />
    </g>
  </svg>
</template>
