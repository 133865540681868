<script setup></script>
<template>
  <footer class="bg-stone-700 text-stone-200 mt-20">
    <div class="flex mx-auto max-w-screen-lg px-8 py-14">
      <div class="flex-1 text-sm">
        <div class="pb-4 font-bold">Method Foundation</div>
        <div class="text-xs pb-1 font-ringside-narrow">
          A nonprofit 501(c)(3) organization. @ 2024 All rights reserved.
        </div>
        <div class="text-xs font-ringside-narrow text-stone-300">
          <a
            href="/legal"
            class="underline"
            >Membership Agreement</a
          >&ensp;●&ensp;<a
            href="/legal"
            class="underline"
            >Terms & Conditions</a
          >&ensp;●&ensp;<a
            href="/legal"
            class="underline"
            >Privacy Policy</a
          >
        </div>
      </div>
      <div class="flex-1 text-sm hidden">
        <div class="float-right cursor-pointer">
          <a href="mailto:hello@method.foundation">hello@method.foundation</a>
          <div class="flex flex-row-reverse py-4">
            <div class="shrink ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 24 24"
                class="w-6 stroke-stone-300 hover:stroke-stone-100">
                <path
                  d="M7.496,3h9.009c2.482,0 4.495,2.012 4.495,4.496v9.009c0,2.482 -2.012,4.495 -4.496,4.495h-9.008c-2.483,0 -4.496,-2.012 -4.496,-4.496v-9.008c0,-2.483 2.012,-4.496 4.496,-4.496Z"
                  stroke-linecap="round"
                  stroke-width="1"
                  fill-rule="evenodd"
                  fill="none"
                  stroke-linejoin="round"></path>
                <path
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M16.949,6.713c-0.186,0.001 -0.337,0.152 -0.337,0.338c0,0.186 0.152,0.337 0.338,0.337c0.186,0 0.337,-0.151 0.337,-0.337c0.001,-0.187 -0.151,-0.338 -0.338,-0.338"></path>
                <path
                  d="M14.5456,9.45442c1.40589,1.40589 1.40589,3.68528 0,5.09117c-1.40589,1.40589 -3.68528,1.40589 -5.09117,1.77636e-15c-1.40589,-1.40589 -1.40589,-3.68528 -1.77636e-15,-5.09117c1.40589,-1.40589 3.68528,-1.40589 5.09117,-1.77636e-15"
                  stroke-linecap="round"
                  stroke-width="1"
                  fill-rule="evenodd"
                  fill="none"
                  stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="shrink">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="w-6 stroke-stone-300 hover:stroke-stone-100">
                <path
                  d="M16,14.99216A6,6,0,1,1,9.897,9h.10679v3.5H9.95707A2.50241,2.50241,0,0,0,7.574,15.623a2.46493,2.46493,0,0,0,1.70194,1.77446A2.50851,2.50851,0,0,0,12.5,14.99673h.00375V3.5a.5.5,0,0,1,.5-.5h3v.17208A3.9967,3.9967,0,0,0,19.534,7.14134.50626.50626,0,0,1,20,7.635v2.58a.49788.49788,0,0,1-.53037.50232A6.92378,6.92378,0,0,1,16.00375,9.4014h0V15Z"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped></style>
