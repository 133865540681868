import { firestore } from '@/firebase'
import { collection, query, where } from 'firebase/firestore'
import { defineStore, storeToRefs } from 'pinia'
import { useCollection } from 'vuefire'
import { useCartStore } from './cartStore'
import type { Item } from 'firebase/analytics'

export interface Product {
  brand: string
  inStock: boolean
  name: string
  price: number
  sku: string
  variation?: string[]
}

const db = firestore

export const useCatalogStore = defineStore('catalog', () => {
  const catalogRef = collection(db, 'supplies')
  const catalog = useCollection<Product>(query(catalogRef, where('brand', '==', 'AWAKEN')))

  function findProductBySku(sku: string): Product | undefined {
    return catalog.value.find((item: Product) => item.sku === sku)
  }

  return { catalog, findProductBySku }
})
