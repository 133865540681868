<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105.87 83.62">
    <defs>
      
    </defs>
    <g id="Layer_1-2">
      <path
        class="cls-1"
        d="M87.43 79.07s-14.63-2.21-15.78-2.46c-2.6-.56-3.56-1.56-3.56-1.56l.63-.43 3.99 1.45s12.96 1.68 13.32 1.78 1.22.72 2.26 1.15c1.05.43-.15.21-.86.06Z" />
      <path
        class="cls-1"
        d="m67.58 74.86-6.18-2.35.05-.34 7.27 2.45-1.14.24z" />
      <path
        class="cls-1"
        d="m66.93 74.87 1.52.53.27-.78-.42-.21-1.37.46zM60.87 71.95s-.54-.29-.93-.43c-.4-.14-1.57-.42-1.57-.35 0 .04 1.21.38 1.21.38s-.51-.16-1.11-.28c-.49-.1-.94-.19-1.26-.09-.11.03.87.41.87.41s.16.06.63.19c.48.14.53.14.89.24.36.11 1.03.24 1.03.24l.78.25.32-.24-.84-.32ZM104.05 81.49s-1.97-.6-2.94-.98c-.32-.13-.64-.34-.83-.39-.18-.06-.28 0-.46-.05-.52-.12-2.24-.69-2.67-.77-.43-.08-.95.37-1.65.25-1.32-.22-5.41-1.15-5.41-1.15h-.23l2.8 1.5 7.65 1.56 3.74.03Z" />
      <path
        class="cls-1"
        d="M64.28 73.58s2.13.46 5.25 1.11c3.13.65 3 .83 6.82 1.83 6.77 1.77 11.12 3.51 12.26 3.51.76 0 2.99-1.25 2.99-1.25l-1.05-.37s-1.66-.81-2.74-1.25c-1.18-.49-2.62-.99-3.69-1.21-1.34-.28-1.55-.15-2.39-.28-3.28-.5-8.21-1.96-9.6-2.3-1.4-.34-6.06-1.93-6.06-1.93l-.21.85-1.58 1.3ZM5.11 60.9s1.03.69 1.52.78c.17.03 9.06 1.32 15.33 2.31 5.56.88 12.66 1.59 12.66 1.59s1.54.98 1.03 1.02c-1.12.08-3.59.08-14.39-1.96-2.98-.56-15.7-2.87-15.7-2.87l.04-.36-.5-.49v-.02Z" />
      <path
        class="cls-1"
        d="M6.45 61.93s-.33 0-.72-.09c-.19-.04-.53-.04-.53-.04s-.15-.06-.3-.14c-.14-.07-.13-.12-.13-.12h.55s-.07-.06-.25-.19c-.18-.12-1.07-.55-1.06-.66s.14-.31.35-.3c.21.02.33.08.58.29.11.1.17.17.28.34.14.22 1 .66 1 .66l.23.23ZM36.56 65.87s3.43.79 12.85 2.65c9.42 1.87 13.25 2.57 13.25 2.57l.45 1.66s-17.41-3.84-21.94-4.79-9.57-2.07-9.57-2.07l4.94-.02Z" />
      <path
        class="cls-1"
        d="M34.62 65.59s1.86.01 11.28 1.88c9.42 1.87 16.76 3.63 16.76 3.63l.45 1.66s-17.41-3.84-21.94-4.79-9.57-2.07-9.57-2.07l3.01-.31Z" />
      <path
        class="cls-1"
        d="m7.3 62.1-.14-.38c2.84.44 9.86 1.5 14.8 2.28 5.56.88 14.69 1.89 14.69 1.89s-.48.68-1 .71c-1.12.08-3.59.08-14.39-1.96-2.31-.44-10.43-1.91-13.96-2.55ZM27.59 56.28s-.63 0-.66.13c0 .03 7.37 2.94 11.92 4.75 4.04 1.6 10.66 4.06 10.66 4.06s-.19.52-.85.37c-1.44-.31-5.12-1.47-12.15-4.6q-2.52-1.12-10.44-4.54l.47-.3.83-.06.22.19Z" />
      <path
        class="cls-1"
        d="M50.98 65.74s1.42.36 8.11 3.14c6.5 2.71 8.88 3.87 8.88 3.87l-8.36-1.44s-5.49-2.86-8.56-4.17c-3.07-1.31-5.85-2.67-5.85-2.67l5.78 1.26Z" />
      <path
        class="cls-1"
        d="m27.22 56.95.76-.06c2.08.82 7.27 2.84 10.86 4.26 4.04 1.6 12.13 4.59 12.13 4.59s-1.66-.01-2.33-.15c-1.44-.31-5.12-1.47-12.15-4.6-1.5-.67-6.92-3.02-9.28-4.04ZM26.07 56.45s-.48-.07-.48-.15c0-.04-.11-.24-.11-.24s-.06-.02.11-.02c.15 0 .33.04.33.04l-.06-.12s.11 0 .41.03c.29.02.89-.03 1.27.01.37.05 1.14.16 1.29.23.15.07.11.1-.31.11-.19 0-.62-.04-1.06-.06-.54-.03-.72.03-.72.03l-.67.14ZM66.32 73.71s-4.12-.65-8.5-1.84c-3.23-.88-10.15-2.78-10.15-2.78l11.92 1.69s-.57-1.1 2.42-.28c2.99.82 4.76 1.48 4.76 1.48l-.64.53.19 1.19ZM89.08 77.72l2.64 1.33-.47-.71-2.17-.62zM85.38 77.81s-13.87-4.71-14.73-5.1c-1.94-.88-2.34-1.21-2.34-1.21l1.08-.37 4.4 1.74S86.7 76.9 86.9 77.03c.2.13-.38.57-.17.99.21.43-.81.03-1.35-.21ZM67.82 71.28l-6-2.57.29-.31 7.28 2.72-1.57.16z" />
      <path
        class="cls-1"
        d="m67.01 71.26 1.51.59.87-.73-.37-.22-2.01.36zM61.56 68.15s-.46-.31-.85-.46c-.39-.16-1.65-.49-1.7-.42-.03.04 1.23.43 1.23.43s-.52-.18-1.18-.33c-.53-.12-1.34-.35-1.8-.28-.16.03.93.49.93.49s-.02.05.47.21c.5.16.9.23 1.27.35.37.12 1.1.28 1.1.28l.79.28.56-.22-.82-.35ZM104.4 81.55s2.06.91 1.3 1.64c-.79.77-3.36.37-5.65-.19-3.58-.88-4.03-1.37-6.24-1.16-2.03.19-4.23 0-6.53-.57 0 0-4.49.45-7.5-.96-.82-.38-2.76-.56-2.92-1.27s.72-1.08 2.25-.9c2.72.31 2.45-.3 2.47-.5.04-.38 1.01-.54 1.76-.52 1.47.03 4.38.47 7.38 1.56.6.22 2.49 1.54 4.75 1.52 1.73-.01 6.6 1.24 6.6 1.24s.16-.1 1.13-.1c.4 0 1.2.19 1.2.19Z" />
      <path
        class="cls-3"
        d="M7.91 10.91s-3.23 8.22-3.31 8.9c-.18 1.54 1.68 2.48 1.68 2.48l1.41-.11-1.16-2.64s3.53-7.15 3.49-7.37c-.04-.23-1.03-.94-1.46-1.65-.43-.72-.61-.03-.66.39ZM6.46 22.63l2.07 4.14.84.14-1.67-4.73-1.24.45z" />
      <path
        class="cls-2"
        d="m6.05 22.94-.39-.99 2.04.23.26.31-1.91.45z" />
      <path
        class="cls-3"
        d="M9.58 27.29s.39.4.5.66c.11.26.11.97-.07.93-.1-.02-.21-.77-.21-.77s.09.33.04.68c-.04.29-.1.55-.51.66-.15.04-.49-.62-.49-.62s-.05-.1-.1-.4c-.05-.3-.02-.32-.07-.55s.02-.62.02-.62l-.16-.5.78-.04.27.56ZM11.78 1.62s.3 1.25.66 1.9c.12.22.44.47.46.59.02.12-.15.14-.16.24-.01.31.36 1.43.31 1.67-.05.25-1.45.29-1.58.69-.25.75-.38 3.2-.38 3.2l-.16.11-1.97-2.08.7-4.49 2.12-1.85Z" />
      <path
        class="cls-3"
        d="M7.61 24.85s.13-1.26.39-3.1-.25-1.87-.44-4.21c-.33-4.16-1.97-7.11-1.31-7.67.44-.37 4.77-.88 4.77-.88l.29.69s.98 1.2 1.42 1.93c.49.81.85 1.75.77 2.38-.1.79-.56.83-.74 1.3-.71 1.85-.06 4.95-.05 5.8 0 .84 1.12 3.88 1.12 3.88l-2.17-.3-4.06.17ZM3.62 59.86s-1.06-.83-1-1.12c.02-.1 2.1-5.07 3.39-8.61 1.14-3.14 3.58-6.96 3.58-6.96s-1.47-1.22-1.87-.99c-.85.51-2.29 1.72-3.69 7.98-.38 1.73-2.25 9.05-2.25 9.05l.9.15.89.48h.05Z" />
      <path
        class="cls-3"
        d="M1.93 58.71s-.19.16-.21.39c0 .11-.23.28-.23.28s.05.1.15.21c.1.1.22.12.22.12l.35-.26s.07.06.27.2.7.78.98.83c.28.05.84.08.92-.03.08-.11 0-.2-.36-.42-.16-.1-.31-.16-.67-.3-.44-.17-1-.8-1-.8l-.43-.22ZM10.02 42.09s.11-2.05 1.11-7.55 1.54-7.71 1.54-7.71l-3.75-1.01S8 36.17 7.67 38.84c-.34 2.67-.59 5.67-.59 5.67l2.94-2.42Z" />
      <path
        class="cls-3"
        d="M9.59 43.17s1.06-.92 2.06-6.42 1.03-9.93 1.03-9.93l-3.75-1.01s-.92 10.35-1.25 13.02c-.34 2.67-.59 5.67-.59 5.67l2.51-1.33Z" />
      <path
        class="cls-3"
        d="m2.02 58.22.84.25c.6-1.6 2.14-5.54 3.15-8.34 1.14-3.14 4.02-8.1 4.02-8.1s-1.92-.08-2.31.15c-.85.51-2.29 1.72-3.69 7.98-.3 1.34-1.49 6.02-2.01 8.05ZM26.57 54.67s-.77.16-1.19.09c-.1-.02-1.79-5.19-3.19-8.69-1.23-3.11-2.77-8.09-2.77-8.09s-1.63-.21-1.77.23c-.3.95-.02 3.6 3.09 9.21.86 1.55 4.33 8.26 4.33 8.26l.87-.52.93-.17-.29-.32Z" />
      <path
        class="cls-3"
        d="M19.13 36.88s.16-.97-2.25-6.19c-2.35-5.08-3.71-7.03-3.71-7.03l-2.81 5.31s3.63 4.63 4.82 7.04c1.19 2.42 2.78 4.7 2.78 4.7l1.18-3.83Z" />
      <path
        class="cls-3"
        d="m24.58 54.77.79-.37c-.61-1.59-2.09-5.56-3.18-8.32-1.23-3.11-3.06-9.19-3.06-9.19s-1.35.89-1.49 1.33c-.3.95-.02 3.6 3.09 9.21.67 1.2 2.88 5.49 3.85 7.35ZM25.06 55.68s.06.32.29.36c.11.02.24.26.24.26s.11-.03.23-.12c.11-.08.15-.2.15-.2l-.21-.39s.09-.04.3-.16.93-.22 1.16-.39c.23-.17.64-.56.61-.69-.03-.13-.15-.16-.56-.05-.19.05-.48.24-.82.4-.43.2-.94.22-.94.22l-.44.76ZM8.49 23.79s-.85 2.33-.53 5.04c.24 2 .77 6.29.77 6.29l2.91-6.64s2.33.8 2.09-1.06c-.24-1.86-.8-3.03-.8-3.03l-1.67.06-2.78-.66Z" />
      <path
        class="cls-2"
        d="m12.13 10.73-1.66-1.92 1.43.57.23 1.35z" />
      <path
        class="cls-3"
        d="M9.75 12.51s3.28 9.02 3.72 9.63c1 1.37 1.55 1.72 1.55 1.72l1.53-.35-1.64-2.98s-2.18-8.23-2.38-8.39c-.2-.16-1.6-.08-2.5-.38-.91-.3-.55.38-.29.76Z" />
      <path
        class="cls-3"
        d="m15.25 24.2 2.7 4.16h.94l-2.33-4.86-1.31.7z" />
      <path
        class="cls-2"
        d="m14.83 24.61-.54-1.02 2.27-.09.32.29-2.05.82z" />
      <path
        class="cls-3"
        d="M19.16 28.75s.47.37.62.63c.15.27.22 1.04.02 1.03-.11 0-.31-.8-.31-.8s.13.34.11.73c-.02.32.06.82-.38 1.01-.16.07-.65-.69-.65-.69s-.14-.01-.23-.33-.04-.55-.11-.79-.04-.67-.04-.67l-.23-.52.85-.18.36.57ZM11.85 1.42S10.86-.02 8.64 0C6.31.02 5.77 1.48 5.79 2.86c.03 2.17.94 2.62-.86 3.61-1.65.9-2.46 2.08-2.44 3.47 0 0-3.71 1.99-2.07 4.13.45.58-.27 1.62 1.35 2.03 1.62.42 3.02.16 3.49-.68.84-1.48 2.16-1.06 2.65-.98.95.16 1.89-.24 2.29-.61.78-.74 1.42-2.37.56-4.36-.17-.4-2.27-1.95-.9-3.05 1.05-.84.86-3.82.86-3.82s.34-.03.9-.51c.23-.2.24-.68.24-.68Z" />
    </g>
  </svg>
</template>
