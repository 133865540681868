<script setup lang="ts">
  import { computed } from 'vue'
  import { useWindowSize } from '@vueuse/core'

  const { width } = useWindowSize()
  const isMobile = computed(() => width.value < 1024)

  const props = defineProps<{
    productImage: string
  }>()

  const imageClass = computed(() => `bg-${props.productImage}`) // Create dynamic class
</script>
<template>
  <div id="product">
    <MessageBar></MessageBar>
    <ShopMenu></ShopMenu>
    <div>
      
      <div
        id="product"
        class="md:px-10 lg:px-0">
        <div class="flex flex-col gap-10 lg:flex-row mx-auto max-w-screen-md lg:max-w-screen-lg lg:px-10 py-8 lg:py-0">
          <div class="flex-1 lg:pt-10">
            <div
              id="heroImage"
              class="sticky top-10">
              <PortalBlock class="w-[21rem] lg:w-[23rem] bottom-14 lg:bottom-16"></PortalBlock>
              <div
                :class="imageClass"
                class="w-full aspect-square bg-cover bg-center bg-no-repeat"></div>
            </div>
          </div>
          <slot name="purchaseBlock"></slot>
        </div>
        <div
          id="features"
          class="mx-auto max-w-screen-md lg:max-w-screen-lg flex flex-col px-10 my-10 md:px-20 lg:px-0 lg:flex-row">
          <slot name="features"></slot>
        </div>

        <template v-if="isMobile">
          <div
            id="content"
            class="flex flex-col flex-wrap gap-24 px-10 lg:px-0 lg:gap-20 max-w-screen-md lg:max-w-screen-lg mx-auto">
            <slot name="sidebar"></slot>
            <slot name="content"></slot>
          </div>
        </template>
        <template v-else>
          <div
            id="content"
            class="flex gap-10 px-10 lg:px-0 lg:gap-20 max-w-screen-md lg:max-w-screen-lg mx-auto">
            <div class="flex flex-col gap-10 w-2/5">
              <slot name="sidebar"></slot>
            </div>
            <div class="flex flex-col gap-10 w-3/5"><slot name="content"></slot></div>
          </div>
        </template>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>