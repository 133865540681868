<script setup lang="ts">
  import { useDonationStore } from '@/stores/donationStore'
  import { storeToRefs } from 'pinia'
  import { useMemberStore } from '@/stores/memberStore'
  import { useDonationDetails } from '../utilities/DonationView/useDonationDetails'
  import { ref, watch } from 'vue'
  import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
  import { firestore } from '@/firebase'
  import type { Donation } from '../utilities/DonationView/Donation'
  import { useCurrentUser } from 'vuefire'
  import ShopMenu from '@/components/layout/utilities/ShopMenu.vue'

  const { memberRecord } = storeToRefs(useMemberStore())
  const { donationId, donationRecord } = storeToRefs(useDonationStore())
  const { donationDetails } = useDonationDetails()
  const member = useCurrentUser()

  const recentDonations = ref<Donation[]>([])

  watch(
    member,
    async () => {
      if (member.value) {
        try {
          const q = query(
            collection(firestore, 'donations'),
            where('memberId', '==', member.value.uid),
            orderBy('timestamp', 'desc'),
            limit(5)
          )
          const querySnapshot = await getDocs(q)
          recentDonations.value = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            memberId: doc.data().memberId,
            stripeCheckoutSession: doc.data().stripeCheckoutSession,
            stripeCheckoutSessionId: doc.data().stripeCheckoutSessionId,
            timestamp: doc.data().timestamp,
            trackingHistory: doc.data().trackingHistory,
            trackingNumber: doc.data().trackingNumber,
            trackingStatus: doc.data().trackingStatus,
          }))
        } catch (error) {
          console.error('Error fetching donations:', error)
        }
      }
    },
    { immediate: true }
  )
</script>
<template>
  <div class="flex flex-col min-h-screen">
    <MessageBar></MessageBar>
    <ShopMenu></ShopMenu>
    <div class="max-w-screen-sm mx-auto flex flex-col flex-grow">
      <FadeTransition>
        <template v-if="donationId && donationRecord">
          <div>
            <div class="text-center text-5xl font-ringside-narrow font-bold tracking-tight leading-tight pb-10">
              Thank you<span v-if="memberRecord.preferredName">, {{ memberRecord.preferredName }}</span>
            </div>
            <div class="text-center mx-auto px-10">
              Thank you for your generous donation. Please retain your receipt as all donations are tax deducible. Have
              questions?
              <a
                href="mailto:hello@method.foundation"
                class="underline underline-offset-2 decoration-stone-200"
                >Send us a message
              </a>
              and we will be happy to assist you.
            </div>
            <div class="pt-10 md:pt-20 flex flex-col">
              <div class="flex flex-col md:flex-row">
                <DonationStatusStep
                  status="received"
                  :active="true" />
                <DonationStatusStep
                  status="shipped"
                  :active="
                    donationRecord.trackingStatus &&
                    ['TRANSIT', 'DELIVERED'].includes(donationRecord.trackingStatus.status)
                  " />
                <DonationStatusStep
                  status="delivered"
                  :active="
                    donationRecord.trackingStatus && ['DELIVERED'].includes(donationRecord.trackingStatus.status)
                  " />
              </div>
            </div>
            <div class="pt-10 md:pt-20 flex flex-col gap-10 md:flex-row px-10">
              <div class="flex-3">
                <div class="font-ringside-narrow uppercase text-sm font-bold pb-5">Shipping Address:</div>
                <div>{{ donationDetails.shipping.name }}</div>
                <div>{{ donationDetails.shipping.address.line1 }}</div>
                <div v-if="donationDetails.shipping.address.line2">
                  {{ donationDetails.shipping.address.line2 }}
                </div>
                <div class="flex">
                  <div>{{ donationDetails.shipping.address.city }},</div>
                  <div class="px-2.5">
                    {{ donationDetails.shipping.address.state }}
                  </div>
                  <div>{{ donationDetails.shipping.address.postal_code }}</div>
                </div>
              </div>
              <ShipmentStatusBlock class="flex-2" />
            </div>
            <div
              v-if="donationDetails.lineItems"
              class="mx-10 mt-10 md:mt-20 text-lg font-ringside-narrow border-t-[5px] border-solid border-stone-400">
              <DonationItem
                v-for="(item, index) in donationDetails.lineItems"
                :key="index"
                :sku="item.description"
                :quantity="item.quantity"
                :price="item.amount_subtotal" />
            </div>
            <div class="flex justify-between mx-10 text-sm py-3 border-b border-solid border-stone-300">
              <div class="font-ringside-narrow font-medium">Shipping:</div>
              <div class="">Free <span class="line-through pl-2 text-stone-400">$12</span></div>
            </div>
            <div
              v-if="donationDetails.accountBalanceApplied"
              class="flex justify-between mx-10 text-sm py-3 border-b border-solid border-stone-200">
              <div class="font-ringside-narrow font-medium">Account balance:</div>
              <div class="">
                -{{
                  donationDetails.accountBalanceApplied.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
              </div>
            </div>
            <div class="flex justify-between mx-10 text-sm py-3 border-b border-solid border-stone-100">
              <div class="pt-2 font-ringside-narrow font-medium">Total donation:</div>
              <div class="text-2xl font-ringside-condensed tracking-tight">
                {{
                  donationDetails.grandTotal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="memberRecord">
          <div>
            <div
              class="text-center text-4xl md:text-5xl font-ringside-narrow font-bold tracking-tight leading-tight pb-10 relative">
              Thank you<span v-if="memberRecord.preferredName">, {{ memberRecord.preferredName }}</span>
            </div>
            <div class="text-center mx-auto px-10">
              Please find your most recent donations below. Have questions?
              <a
                href="mailto:hello@method.foundation"
                class="underline underline-offset-2 decoration-stone-200"
                >Send us a message</a
              >
              and we will be happy to assist you.
            </div>
            <div class="my-10 md:my-20 mx-10 border-t-[5px] border-solid border-stone-400">
              <template v-if="recentDonations.length > 0">
                <DonationSummary
                  v-for="donation in recentDonations"
                  :key="donation.id"
                  :donation="donation" />
              </template>
              <template v-else>
                <div class="text-center mx-auto p-20">No transactions found.</div>
              </template>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="min-h-screen"></div>
        </template>
      </FadeTransition>
    </div>
    <Footer class="justify-self-end"></Footer>
  </div>
</template>
