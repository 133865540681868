import { useCartStore } from '@/stores/cartStore'
import stripePromise from '@/stripe'
import { getFunctions, httpsCallable, type HttpsCallableResult } from 'firebase/functions'
import { storeToRefs } from 'pinia'
import { elementsOptions } from './StripeElementsOptions'
import type { StripeCustomCheckoutSession } from '@stripe/stripe-js'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { useAnalyticsItemGenerators } from '../analytics/AnalyticsItemGenerators'
import { useMemberStore } from '@/stores/memberStore'

interface StripeCheckoutSessionInitializationData {
  sessionId: string
  clientSecret: string
}

const functions = getFunctions()
const initializeCheckoutSessionFunction = httpsCallable(functions, 'api-initializeCheckout')

export async function useStripeCustomCheckout() {
  const {
    cart,
    grandTotal,
    totalQty,
    stripeCustomCheckoutSessionId,
    stripeCustomCheckout,
    checkoutHasShippingAddress,
    checkoutHasPaymentDetails,
  } = storeToRefs(useCartStore())

  const { generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()

  const { memberRecord } = storeToRefs(useMemberStore())

  const initializeCheckoutSession = async () => {
    console.log('useStripeCustomCheckout - initializeCheckoutSession', memberRecord.value, totalQty.value)
    if (!memberRecord.value || totalQty.value == 0) return

    try {
      const stripe = await stripePromise

      const sessionInitData: HttpsCallableResult<StripeCheckoutSessionInitializationData> =
        (await initializeCheckoutSessionFunction({
          cart: cart.value,
          live: true, // Live
        })) as HttpsCallableResult<StripeCheckoutSessionInitializationData>

      if (sessionInitData) {
        stripeCustomCheckoutSessionId.value = sessionInitData.data.sessionId

        if (stripe) {
          stripeCustomCheckout.value = await stripe.initCustomCheckout({
            clientSecret: sessionInitData.data.clientSecret,
            elementsOptions,
          })

          stripeCustomCheckout.value.on('change', (session: StripeCustomCheckoutSession) => {
            if (session.confirmationRequirements.includes('shippingAddress')) {
              checkoutHasShippingAddress.value = false
            } else if (
              !checkoutHasShippingAddress.value &&
              session.confirmationRequirements &&
              !session.confirmationRequirements.includes('shippingAddress')
            ) {
              logEvent(analytics, 'add_shipping_info', {
                value: grandTotal.value,
                currency: 'USD',
                items: generateAnalyticsItemsFromCart(),
              })
              checkoutHasShippingAddress.value = true
            }

            // This isn't being sent consistently because of Stripe
            if (session.confirmationRequirements.includes('paymentDetails')) {
              checkoutHasPaymentDetails.value = false
            } else if (
              !checkoutHasPaymentDetails.value &&
              session.confirmationRequirements &&
              !session.confirmationRequirements.includes('paymentDetails')
            ) {
              logEvent(analytics, 'add_payment_info', {
                value: grandTotal.value,
                currency: 'USD',
                items: generateAnalyticsItemsFromCart(),
              })
              checkoutHasPaymentDetails.value = true
            }
          })
        } else {
          console.error('Error: Stripe not initialized.')
        }
      }
    } catch (error) {
      console.error('Error fetching checkout session:', error)
      // Optionally handle or throw the error as needed
    }
  }

  return {
    initializeCheckoutSession,
  }
}
