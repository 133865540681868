<script setup lang="ts">
const props = defineProps<{
  title: string
  description: string
  skuMask: string
  selection: string
  subtext: string
}>()

const emit = defineEmits(['variation-change'])

const handleClick = () => {
  emit('variation-change', props.skuMask)
}
</script>
<template>
  <div
    @click="handleClick"
    class="flex-1 box-border border border-solid border-stone-300 hover:border-stone-400 p-5 cursor-pointer"
    :class="
      title == selection ? 'border-2 border-solid border-stone-500 bg-stone-50 shadow-inner' : ''
    "
  >
    <div class="font-ringside-narrow font-bold uppercase text-xs text-stone-500 pb-0.5">
      Mode {{ title }}
    </div>
    <div class="text-xs">
      <div class="text-stone-500">{{ description }}</div>
      <div class="">{{ subtext }}</div>
    </div>
  </div>
</template>
<style scoped></style>
